import React from 'react'

import { Box, Typography } from '@mui/material'

import CustomButton from '../../components/Button'
import CustomModal from '../../components/CustomModal'

import styled from 'styled-components'

const StyledRemoveModal = styled(CustomModal)`
  .remove-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      color: #1d1d1d;

      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }

    &-subtitle {
      margin-top: 4px;
      color: #6f6f6f;
      font-size: 16px;
      font-weight: 450;
      line-height: 140%;
      text-align: center;
    }

    .buttons-block {
      margin-top: 24px;
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
`

const RemoveModal = ({ onClose, onDelete, ...props }) => {
  return (
    <StyledRemoveModal {...props} sx={{ '.modal-body': { width: '420px' } }}>
      <Box className='remove-modal'>
        <Typography className='remove-modal-title'>Remove a programme</Typography>

        <Typography className='remove-modal-subtitle'>Are you sure you want to remove the programme?</Typography>

        <Box className='buttons-block'>
          <CustomButton
            sx={{
              backgroundColor: '#fff',
              color: '#2D4960',
              '&:hover': {
                color: '#1D1D1D',
                backgroundColor: '#fff',
                boxShadow: 'none'
              }
            }}
            onClick={onClose}
          >
            Cancel
          </CustomButton>

          <CustomButton
            onClick={onDelete}
            isLight
            sx={{
              color: '#2D4960'
            }}
          >
            Delete
          </CustomButton>
        </Box>
      </Box>
    </StyledRemoveModal>
  )
}

export default RemoveModal
