import { SvgIcon } from '@mui/material'

const BookIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ width: 32, height: 32, fill: 'none !important', ...sx }}
      {...props}
      viewBox='0 0 24 24'>
      <path
        stroke='#2D4960'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z'></path>
      <path
        stroke='#2D4960'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z'></path>
    </SvgIcon>
  )
}

export default BookIcon
