import { SvgIcon } from '@mui/material'

const InfoIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', width: 16, height: 16, ...sx }}
      {...props}
      viewBox='0 0 16 16'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'>
        <circle cx='8' cy='8' r='6.5' stroke='#2D4960' />
        <path
          d='M8.00065 11.3327C8.18954 11.3327 8.34798 11.2687 8.47598 11.1407C8.60354 11.0131 8.66732 10.8549 8.66732 10.666V7.98268C8.66732 7.79379 8.60354 7.63824 8.47598 7.51602C8.34798 7.39379 8.18954 7.33268 8.00065 7.33268C7.81176 7.33268 7.65354 7.39646 7.52598 7.52402C7.39798 7.65202 7.33398 7.81046 7.33398 7.99935V10.6827C7.33398 10.8716 7.39798 11.0271 7.52598 11.1494C7.65354 11.2716 7.81176 11.3327 8.00065 11.3327ZM8.00065 5.99935C8.18954 5.99935 8.34798 5.93535 8.47598 5.80735C8.60354 5.67979 8.66732 5.52157 8.66732 5.33268C8.66732 5.14379 8.60354 4.98535 8.47598 4.85735C8.34798 4.72979 8.18954 4.66602 8.00065 4.66602C7.81176 4.66602 7.65354 4.72979 7.52598 4.85735C7.39798 4.98535 7.33398 5.14379 7.33398 5.33268C7.33398 5.52157 7.39798 5.67979 7.52598 5.80735C7.65354 5.93535 7.81176 5.99935 8.00065 5.99935Z'
          fill='#2D4960'
        />
      </svg>
    </SvgIcon>
  )
}

export default InfoIcon
