import CustomModal from '../../components/CustomModal'

import styled from 'styled-components'

export const StyledChangeProgrammeModal = styled(CustomModal)`
  .modal-body {
    @media (max-width: 1024px) {
      padding: 24px;
    }
  }

  .change-modal {
    padding: 16px 0px 0px 16px;

    @media (max-width: 1024px) {
      padding: 0;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-close {
      padding: 6px;
    }

    &-title {
      color: #1d1d1d;
      font-size: 40px;
      font-weight: 500;
      line-height: 52px;

      @media (max-width: 1024px) {
        font-size: 22px;
        font-weight: 500;
        line-height: 28.6px;
      }
    }

    &-form {
      margin-top: 16px;
      max-height: 640px;
      padding-bottom: 32px;
      padding-right: 16px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #dadada;

        border: 4px solid #dadada;

        border-radius: 99999px;
      }

      @media (max-width: 1024px) {
        padding-right: 0;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .buttons-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .calendar-label {
    ${({ theme }) => theme.typography.textSecondary};
    line-height: 21px;
    white-space: initial;
    margin-bottom: 0.8rem;
  }

  .calendar-label-grey {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: #8c8c8c;
  }

  .submit-button {
    &.Mui-disabled {
      color: #fff;
      background-color: rgba(24, 28, 42, 0.3);
    }
  }
`
