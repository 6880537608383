import React from 'react'

import { Box, Typography } from '@mui/material'

import CrossMarkIcon from '../../assets/icons/CrossmarkIcon'
import CustomModal from '../../components/CustomModal'

import styled from 'styled-components'

const StyledTooltipModal = styled(CustomModal)`
  .modal-body {
    padding: 24px;
    width: 100%;
  }

  .tooltip-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      color: #1d1d1d;

      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
  }
`

const TooltipModal = ({ title, children, componentsProps, onClose, ...props }) => {
  return (
    <StyledTooltipModal {...props} sx={componentsProps}>
      <Box className='tooltip-modal'>
        <Box className='tooltip-modal-header'>
          <Typography className='tooltip-modal-title'>{title ?? ''}</Typography>
          <Box className='modal-close' onClick={onClose}>
            <CrossMarkIcon />
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </StyledTooltipModal>
  )
}

export default TooltipModal
