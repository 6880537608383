import { SvgIcon } from '@mui/material'

const TrashIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 24, height: 24, fill: 'none !important', ...sx }} {...props} viewBox='0 0 24 24'>
      <path
        d='M17.25 6.66667L16.5492 17.3448C16.4879 18.28 16.4572 18.7477 16.2583 19.1022C16.0833 19.4144 15.8192 19.6654 15.501 19.822C15.1395 20 14.6781 20 13.7555 20H10.2445C9.32186 20 8.86052 20 8.49903 19.822C8.18077 19.6654 7.91672 19.4144 7.74166 19.1022C7.54281 18.7477 7.51213 18.28 7.45075 17.3448L6.75 6.66667M5 6.66667H19M15.5 6.66667L15.2632 5.94495C15.0337 5.24556 14.919 4.89586 14.7061 4.63732C14.5182 4.40901 14.2768 4.23229 14.0042 4.12336C13.6954 4 13.3326 4 12.6069 4H11.3931C10.6674 4 10.3046 4 9.99582 4.12336C9.72318 4.23229 9.48182 4.40901 9.29388 4.63732C9.08105 4.89586 8.9663 5.24556 8.73681 5.94495L8.5 6.66667'
        stroke='#2D4960'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default TrashIcon
