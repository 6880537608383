import cn from 'classnames'

import { Box, InputLabel, TextField, Typography } from '@mui/material'

import styled from 'styled-components'

const StyledTextField = styled(Box)`
  .text-field {
    width: 100%;

    &-label {
      ${({ theme }) => theme.typography.textSecondary};
      line-height: 21px;
      white-space: initial;
      margin-bottom: 0.8rem;

      &-error {
        color: #c8102e;
      }
    }

    &-error {
      &-message {
        margin-top: 4px;
        ${({ theme }) => theme.typography.textSmall};
        font-size: 12px;
        line-height: 18px;
        color: #c8102e;
      }

      .MuiSvgIcon-root {
        path {
          fill: #c8102e;
        }
      }
    }
  }

  .text-field-border-error {
    border-color: #c8102e;
  }

  .active {
    .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
      border-color: #6f6f6f !important;
    }

    .Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: #c8102e !important;
    }
  }
`

const Input = ({
  value,
  onChange,
  label,
  placeholder,
  error,
  endAdornment,
  containerClass = '',
  className,
  id,
  helperText,
  ...props
}) => {
  return (
    <StyledTextField className={containerClass}>
      {label && (
        <InputLabel
          className={cn('text-field-label', {
            'text-field-label-error': error
          })}
          shrink={false}
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      <TextField
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        error={!!error}
        className={cn('text-field', {
          [className]: className,
          active: !!value,
          'text-field-error': error
        })}
        InputProps={{
          endAdornment: endAdornment
        }}
        {...props}
      />

      {helperText && (
        <Typography className='text-field-label' sx={{ mt: 0.5 }}>
          {helperText}
        </Typography>
      )}

      {error && error !== true && <Box className='text-field-error-message'>{error}</Box>}
    </StyledTextField>
  )
}

export default Input
