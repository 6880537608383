import { Tooltip } from '@mui/material'

import styled from 'styled-components'

export const StyledTooltip = styled(({ className, width, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  .MuiTooltip-tooltip {
    max-width: ${({ width }) => (width ? `${width}px` : 'none')};
  }
`
