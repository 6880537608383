import cn from 'classnames'
import { Toaster, resolveValue } from 'react-hot-toast'

import { Box } from '@mui/material'

import AlertIcon from '../../assets/icons/AlertIcon'
import { StyledToast } from './styled'

const Toast = () => {
  return (
    <Toaster
      position='top-center'
      toastOptions={{
        duration: 3000
      }}
    >
      {t => {
        return (
          <StyledToast>
            <Box
              className={cn('toast', {
                'toast-error': t.type === 'error'
              })}
            >
              <AlertIcon />
              <p className='toast-text'>Something went wrong: {resolveValue(t.message, t)}</p>
            </Box>
          </StyledToast>
        )
      }}
    </Toaster>
  )
}

export default Toast
