import { SvgIcon } from '@mui/material'

const PriceBreakdownIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 24, height: 24, fill: 'none', ...sx }} {...props} viewBox='0 0 24 24'>
      <path
        d='M9 11H15M9 7H13M9 15H15M5 6.2V21L7.5 19L10 21L12 19L14 21L16.5 19L19 21V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2Z'
        stroke='#6F6F6F'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default PriceBreakdownIcon
