import { Modal } from '@mui/material'

import styled from 'styled-components'

export const StyledPopup = styled(Modal)`
  & .MuiBackdrop-root {
    color: #181c2a;
    background-color: #181c2a;
    opacity: 0.3 !important;
  }

  .guarantee-popup {
    position: absolute;
    top: 135px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 343px;
    background-color: #fff;
    outline: none;
    border-radius: 24px;
    background: #ffffff;
    overflow: hidden;

    &-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 10rem;
      cursor: pointer;

      svg path {
        stroke: #181c2a;
      }
    }

    &-content {
      padding: 16px 24px;
    }

    &-title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &-title {
      color: #1d1d1d;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }

    &-text {
      color: #1d1d1d;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
    }
  }

  .header-consult-link {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
  }

  .link-text {
    color: #1d1d1d;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.5px;
  }
`
