import { Box, Link, Typography } from '@mui/material'

import CrossMarkIcon from '../../assets/icons/CrossmarkIcon'
import TelIcon from '../../assets/icons/TelIcon'
import { StyledPopup } from './styledPopup'

const TelPopup = ({ isOpen, onClose }) => {
  return (
    <StyledPopup open={isOpen} onClose={onClose}>
      <Box className='guarantee-popup'>
        <Box className='guarantee-popup-content'>
          <Box className='guarantee-popup-title-box'>
            <Typography className='guarantee-popup-title' component='h3'>
              Speak with a Consultant
            </Typography>
            <Box className='guarantee-popup-close' onClick={onClose}>
              <CrossMarkIcon />
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Link className='header-consult-link' href='tel:+44 20 8123 6988'>
              <TelIcon />
              <Typography className='link-text'>+44 20 8123 6988</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </StyledPopup>
  )
}

export default TelPopup
