import { Modal } from '@mui/material'

import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    outline: none;
    border-radius: 32px;
    background: #fff;
    padding: 18px 24px 24px 24px;
    width: 324px;
    height: 402px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .close-button {
    padding-right: 0;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .modal-title {
    color: #1d1d1d;
    font-weight: 450;
    font-size: 16px;
    line-geight: 24px;
  }

  .modal-year {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .modal-year-label {
    color: #6f6f6f;
    font-weight: 450;
    font-size: 16px;
    line-geight: 24px;
  }

  .modal-divider {
    width: 275px;
    height: 0.77px;
    background: #f5f5f7;
    margin-bottom: 8px;
  }

  .modal-months {
    display: flex;
    gap: 2px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 22px;
  }

  .modal-month-button {
    width: 80px;
    height: 42px;
    border-radius: 8px;
    padding: 10px 24px 10px 24px;
    background-color: #fff;
    color: #1d1d1d;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    min-width: 0;

    &:hover {
      background-color: #f1eee9;
      box-shadow: none;
    }

    &.isSelected {
      background-color: #bb945c;
      color: #fff;
    }

    &.Mui-disabled {
      color: rgba(24, 28, 42, 0.3);
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
`
