import React from 'react'

import { Box, Modal } from '@mui/material'

import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    outline: none;
    border-radius: 3.2rem;
    background: #fff;
    padding: 32px;
  }
`

const CustomModal = ({ children, ...props }) => {
  return (
    <StyledModal {...props}>
      <Box className='modal-body'>{children}</Box>
    </StyledModal>
  )
}

export default CustomModal
