import { Box, Link, Tooltip, Typography } from '@mui/material'

import InfoIcon from '../../assets/icons/InfoIcon'
import LetterIcon from '../../assets/icons/LetterIcon'
import ShieldedCheckmarkIcon from '../../assets/icons/ShieldedCheckmarkIcon'
import TelIcon from '../../assets/icons/TelIcon'
import { StyledHeader } from './styled'

const Header = props => {
  return (
    <>
      <StyledHeader component='header' {...props}>
        <Box className='header-container'>
          <Box className='header-consult'>
            <Typography className='header-consult-text'>Speak with a Consultant</Typography>

            <Link className='header-consult-link' sx={{ fontWeight: 500 }} href='tel:+44 20 8123 6988'>
              <TelIcon />
              +44 20 8123 6988
            </Link>

            <Link
              className='header-consult-link'
              sx={{ color: '#BB945C', fontWeight: 300 }}
              href='mailto:advisor@immerse.education'
            >
              <LetterIcon />
              advisor@immerse.education
            </Link>
          </Box>

          <Box className='header-guarantee'>
            <ShieldedCheckmarkIcon />

            <Typography className='header-guarantee-text'>Empowered Learning Guarantee</Typography>

            <Tooltip
              placement='top'
              title={
                <>
                  <Typography className='tooltip-header' sx={{ color: '#484848' }} component='h3'>
                    Empowered Learning Guarantee
                  </Typography>
                  <Typography className='tooltip-text'>
                    We pledge that if our programmes haven't helped to improve your knowledge in your chosen subject
                    area, we will provide you with an Immerse Online Programme entirely for free.
                  </Typography>
                </>
              }
            >
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <InfoIcon sx={{ marginLeft: '6px' }} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </StyledHeader>
    </>
  )
}

export default Header
