import React from 'react'

import Toast from '../../components/Toast'
import PaymentScreen from '../../screens/PaymentScreen/PaymentScreen'

const Payment = () => {
  return (
    <>
      <Toast />
      <PaymentScreen />
    </>
  )
}

export default Payment
