import { useState } from 'react'

import { Box, Link } from '@mui/material'

import LetterIcon from '../../../assets/icons/LetterIcon'
import Logo from '../../../assets/icons/Logo'
import MobileShieldIcon from '../../../assets/icons/MobileShieldIcon'
import TelIcon from '../../../assets/icons/TelIcon'
import EmailPopup from '../../EmailPopup/EmailPopup'
import PledgePopup from '../../PledgePopup/PledgePopup'
import TelPopup from '../../TelPopup/TelPopup'
import { StyledMobileHeader } from './styledMobileHeader'

const MobileHeader = props => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenTelModal, setIsOpenTelModal] = useState(false)
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false)
  return (
    <>
      <StyledMobileHeader component='header' {...props}>
        <Logo sx={{ width: '137px', height: '40px' }} />

        <Box className='header-advisor'>
          {/* <Link className='header-consult-link' sx={{ fontWeight: 500 }} href='tel:+44 20 8123 6988'>
            <TelIcon />
          </Link>
          <Link
            className='header-consult-link'
            sx={{ color: '#BB945C', fontWeight: 300 }}
            href='mailto:advisor@immerse.education'
          >
            <LetterIcon />
          </Link> */}
          <TelIcon onClick={() => setIsOpenTelModal(true)} sx={{ cursor: 'pointer' }} />
          <LetterIcon onClick={() => setIsOpenEmailModal(true)} sx={{ cursor: 'pointer' }} />
          <MobileShieldIcon onClick={() => setIsOpenModal(true)} sx={{ cursor: 'pointer' }} />
        </Box>
      </StyledMobileHeader>
      <TelPopup isOpen={isOpenTelModal} onClose={() => setIsOpenTelModal(false)} />
      <EmailPopup isOpen={isOpenEmailModal} onClose={() => setIsOpenEmailModal(false)} />
      <PledgePopup isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </>
  )
}

export default MobileHeader
