import { Box } from '@mui/material'

import styled from 'styled-components'

export const StyledMobileHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 1.6rem;

  background-color: ${({ theme }) => theme.palette.main.lightGray};

  .header-advisor {
    display: flex;
    align-items: center;
    gap: 28px;
  }

  .header-guarantee {
    display: flex;
    align-items: center;

    &-text {
      margin-left: 1.1rem;
      ${({ theme }) => theme.typography.textMain};
    }
  }

  .header-consult {
    display: flex;
    align-items: center;
    gap: 3.2rem;
    border-radius: 1.6rem;
    transition: background-color 0.2s ease-in-out;

    &-text {
      color: #1d1d1d;
      font-weight: 600;
      font-size: 1.6rem;
    }

    &-link {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 1.6rem;

      svg {
        margin-right: 8px;
      }
    }
  }
`
