import { SvgIcon } from '@mui/material'

const FormIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', width: 24, height: 24, ...sx }}
      {...props}
      viewBox='0 0 24 24'>
      <path
        d='M13.7366 3L20.1061 9.77468V19.5901C20.1061 20.3679 19.4851 21 18.721 21H6.27971C5.51555 21 4.89453 20.3679 4.89453 19.5901V4.40992C4.89453 3.62977 5.51325 3 6.27971 3H13.7366ZM13.7366 3L13.605 8.4893C13.5866 9.2812 14.2122 9.93447 14.9902 9.93447H20.1062'
        stroke='#1D1D1D'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default FormIcon
