import { SvgIcon } from '@mui/material'

const LetterIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ fill: 'none !important', width: 24, height: 24, ...sx }} {...props} viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 5V19H22V5H2ZM4.30047 6L11.1398 10.7875C11.6563 11.1491 12.3437 11.1491 12.8602 10.7875L19.6995 6H4.30047ZM21 6.31033L13.4337 11.6068C12.5729 12.2093 11.4271 12.2093 10.5663 11.6068L3 6.31033V18H21V6.31033Z'
        fill='#6F6F6F'
      />
    </SvgIcon>
  )
}

export default LetterIcon
