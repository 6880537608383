import { StyledTooltip } from './styled'

const CustomTooltip = ({ children, width, title, ...props }) => {
  return (
    <StyledTooltip
      className='tooltip'
      title={title}
      width={width}
      placement='bottom'
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14]
              }
            }
          ]
        }
      }}
      {...props}
    >
      {children}
    </StyledTooltip>
  )
}

export default CustomTooltip
