import { Box } from '@mui/material'

import styled from 'styled-components'

export const StyledMobileFooter = styled(Box)`
  margin: 3.2rem 0 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    margin: 22px 0 0 0;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;
    padding: 0;
    margin-bottom: 3.2rem;

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0 18px;
      justify-content: space-between;
    }

    li {
      width: auto;
      padding: 0;
      ${({ theme }) => theme.typography.sidebar.navigation};
    }
  }

  .sidebar-footer-icons {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    &-determiner {
      height: 1.6rem;
      width: 0.1rem;
      ${({ theme }) => `background-color: ${theme.palette.main.grayBlue}`};
    }

    &-achieve {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      ${({ theme }) => theme.typography.sidebar.navigation};
    }
  }

  .sidebar-footer-subtext {
    margin-top: 1.6rem;
    ${({ theme }) => theme.typography.sidebar.subtext};
  }
`
