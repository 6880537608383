import cn from 'classnames'
import { useState } from 'react'

import { Box, FormControlLabel, IconButton, Radio, Typography, useMediaQuery } from '@mui/material'

import CheckedIcon from '../../assets/icons/CheckedIcon'
import CircleIcon from '../../assets/icons/CircleIcon'
import InfoIcon from '../../assets/icons/InfoIcon'
import CustomTooltip from '../Tooltip'
import TooltipModal from '../TooltipModal'
import { StyledRadioButton } from './styled'

const RadioButton = ({
  value,
  itemValue,
  isDiscount,
  label,
  discount,
  tooltip,
  tooltipWidth,
  info,
  duration,
  discountPrice,
  price,
  className,
  ...props
}) => {
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  const [isOpenTooltipModal, setIsOpenTooltipModal] = useState(false)

  const isBundle = label.includes('+')

  const handleOpenTooltipModal = () => setIsOpenTooltipModal(isOpenTooltipModal => !isOpenTooltipModal)

  return (
    <StyledRadioButton {...props}>
      <FormControlLabel
        key={`radio-button-${itemValue}`}
        className={cn('radio-buttons-button', { checked: value == itemValue, isBundle: isBundle })}
        value={itemValue}
        control={<Radio icon={<CircleIcon />} checkedIcon={<CheckedIcon />} checked={value == itemValue} />}
        label={
          <Box className='radio-buttons-content-wrapper'>
            <Box className='radio-buttons-content-heading'>
              <Box className='radio-buttons-content-heading-box'>
                <Typography className='radio-buttons-content-header'>
                  {label.split(' - ').map((l, key) => (
                    <Typography
                      component='span'
                      sx={{
                        fontSize: '14px',
                        marginTop: '1px',
                        color: key === 1 ? '#7D7C7C' : '#181c2a',
                        fontWeight: 500
                      }}
                    >
                      {key === 1 ? ` - ${l}` : l}
                    </Typography>
                  ))}
                </Typography>
                {isDiscount && !isSmallScreen && (
                  <Box className='radio-buttons-content-discount'>
                    <Typography className='radio-buttons-content-discount-text'>{discount}% off</Typography>
                  </Box>
                )}
              </Box>
              <Box className='radio-buttons-features-box'>
                <Typography className='radio-buttons-content-features'>View features</Typography>
                <CustomTooltip title={tooltip} width={tooltipWidth}>
                  <IconButton
                    className='radio-buttons-content-tooltip'
                    onClick={isSmallScreen ? handleOpenTooltipModal : null}
                  >
                    <InfoIcon />
                  </IconButton>
                </CustomTooltip>
                {isDiscount && isSmallScreen && (
                  <Box className='radio-buttons-content-discount' sx={{ marginLeft: 'auto', marginTop: '4px' }}>
                    <Typography className='radio-buttons-content-discount-text'>{discount}% off</Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className='radio-buttons-content-details'>
              <Box className='radio-buttons-content-box'>
                <Typography className='radio-buttons-content-info'>{info}</Typography>
                {duration &&
                  (isSmallScreen ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '6px' }}>
                      <Box className='radio-buttons-content-dot' />
                      <Typography className='radio-buttons-content-duration'>{duration.replace('•', '')}</Typography>
                    </Box>
                  ) : (
                    <>
                      <Box className='radio-buttons-content-dot' />
                      <Typography className='radio-buttons-content-duration'>{duration.replace('•', '')}</Typography>
                    </>
                  ))}
                {(label === 'Accredited' || label === 'Group Accredited') &&
                  (isSmallScreen ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '6px' }}>
                      <Box className='radio-buttons-content-dot' />
                      <Typography className='radio-buttons-content-duration'>Ages 14-18</Typography>
                    </Box>
                  ) : (
                    <>
                      <Box className='radio-buttons-content-dot' />
                      <Typography className='radio-buttons-content-duration'>Ages 14-18</Typography>
                    </>
                  ))}
              </Box>
              <Box className='radio-buttons-content-price-box'>
                {isDiscount && (
                  <Typography className='radio-buttons-content-discount-price'>
                    £{new Intl.NumberFormat('en-IN').format(price)}
                  </Typography>
                )}
                <Typography className='radio-buttons-content-price'>
                  £
                  {isDiscount
                    ? new Intl.NumberFormat('en-IN').format(discountPrice)
                    : new Intl.NumberFormat('en-IN').format(price)}
                </Typography>
              </Box>
            </Box>
          </Box>
        }
      ></FormControlLabel>
      <TooltipModal title='View Features' open={isOpenTooltipModal} onClose={handleOpenTooltipModal}>
        {tooltip}
      </TooltipModal>
    </StyledRadioButton>
  )
}

export default RadioButton
