export const progressConfig = [
  {
    id: 1,
    text: 'Enrolment',
    field: 'enrolment',
    subtext: (
      <>
        Takes less than 5 minutes <br /> to complete
      </>
    ),
    subList: [
      { text: 'Confirm Programme Options', path: '/start-educational-pathway', field: 'programOptions' },
      { text: 'Additional Programme', path: '/add-another-programme', field: 'additionalProgram' }
    ],
    paths: [
      '/start-educational-pathway',
      '/add-another-programme',
      '/participant-details',
      '/parent-guardian-details',
      '/academic-details',
      '/summary',
      '/payment',
      '/success'
    ],
    childrenPaths: ['/start-educational-pathway', '/add-another-programme']
  },
  {
    id: 5,
    text: 'Student’s Details',
    field: 'studentDetails',
    subList: [
      { text: 'Student’s Personal Details', path: '/participant-details', field: 'studentDetails' },
      { text: 'Student’s Academic Details', path: '/academic-details', field: 'academicDetails' },
      { text: 'Parent/Guardian Details', path: '/parent-guardian-details', field: 'parentGuardianDetails' }
    ],
    paths: [
      '/participant-details',
      '/parent-guardian-details',
      '/academic-details',
      '/summary',
      '/payment',
      '/success'
    ],
    childrenPaths: ['/participant-details', '/parent-guardian-details', '/academic-details']
  },
  {
    id: 10,
    text: 'Payment',
    field: 'payment',
    subList: [
      { text: 'Programme Summary', path: '/summary', field: 'programSummary' },
      { text: 'Payment', path: '/payment', field: 'payment' }
    ],
    paths: ['/summary', '/payment', '/success'],
    childrenPaths: ['/summary', '/payment']
  },
  { id: 6, text: 'Confirmation', field: 'success', paths: [], subList: null, childrenPaths: ['/success'] }
]
