import { Link, useLocation } from 'react-router-dom'

import { Box, List, ListItem, Typography } from '@mui/material'

import StarAchieveIcon from '../../../assets/icons/StarAchieve'
import TruspilotIcon from '../../../assets/icons/TruspilotIcon'
import { selectIsHaveMainProduct } from '../../../store/enrolment/enrolment.selectors'
import { StyledMobileFooter } from './styledMobileFooter'

import { useSelector } from 'react-redux'

const isShouldIncreaseFooter = (pathname, isHaveMainProduct) =>
  isHaveMainProduct &&
  (pathname === '/add-another-programme' ||
    pathname === '/participant-details' ||
    pathname === '/parent-guardian-details' ||
    pathname === '/academic-details' ||
    pathname === '/summary' ||
    pathname === '/payment' ||
    pathname === '/success')

const MobileFooter = props => {
  const { pathname } = useLocation()

  // const isHaveMainProduct = useSelector(selectIsHaveMainProduct)

  return (
    <StyledMobileFooter
      component='footer'
      {...props}
      sx={{
        marginBottom: isShouldIncreaseFooter(pathname, true)
          ? pathname === '/summary'
            ? '16.4rem !important'
            : '11.6rem !important'
          : '13.8rem'
      }}
    >
      <List className='navigation'>
        <a href='https://help.immerse.education/' target='_blank' rel='noreferrer'>
          <ListItem>Help</ListItem>
        </a>
        <a href='https://www.immerse.education/terms-conditions/' target='_blank' rel='noreferrer'>
          <ListItem>Terms and Conditions</ListItem>
        </a>
        <a href='https://www.immerse.education/terms-conditions/' target='_blank' rel='noreferrer'>
          <ListItem>Privacy Policy</ListItem>
        </a>
      </List>
      {/* <Box className='sidebar-footer-icons'>
        <Link href='/'>
          <TruspilotIcon />
        </Link>
        <Box className='sidebar-footer-icons-determiner'></Box>
        <Link href='/' className='sidebar-footer-icons-achieve'>
          <StarAchieveIcon /> 4.9
        </Link>
      </Box> */}
      <Typography className='sidebar-footer-subtext'>©{new Date().getFullYear()} Immerse Education</Typography>
    </StyledMobileFooter>
  )
}

export default MobileFooter
