import { Box } from '@mui/material'

import styled from 'styled-components'

export const StyledSelect = styled(Box)`
  .select {
    cursor: pointer;

    .MuiOutlinedInput-notchedOutline {
      border-color: #dadada !important;
    }

    .MuiSelect-select {
      font-size: 16px;
      font-weight: 450;
      padding-right: 16px;
      min-height: auto;
    }

    .Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: #c8102e !important;
    }

    .MuiSvgIcon-root {
      top: calc(50% - 12px);
      right: 12px;
      transition: transform 0.1s ease-in-out;
    }

    div[aria-expanded='true'] {
      ~ .MuiSvgIcon-root {
        transform: rotate(-180deg);
      }

      ~ .MuiOutlinedInput-notchedOutline {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        border-color: #dadada;
      }
    }

    /* .MuiInputBase-root {
      border-color: #d2d2d2;
      border-radius: 22px;
      padding-right: 16px;

    } */

    &-menu {
      background: red;
    }
  }
`
