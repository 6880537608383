import React, { Fragment, useEffect, useMemo, useState } from 'react'

import { Box, Typography } from '@mui/material'

import InfoIcon from '../../assets/icons/InfoIcon'
import RadioButtonGroup from '../../components/RadioButtonGroup'
import { transferOptions } from '../../constants/transferOptions'
import { selectIsDeposit, selectOrder } from '../../store/enrolment/enrolment.selectors'
import { setIsDeposit } from '../../store/enrolment/enrolment.slice'
import Discount from '../Discount'
import CustomTooltip from '../Tooltip'
import { PriceBreakdownStyled } from './PriceBreakdown.styled'

import { useDispatch, useSelector } from 'react-redux'

const PriceBreakdown = ({ isAccordion }) => {
  const { productOrders, ...order } = useSelector(selectOrder)

  const isDeposit = useSelector(selectIsDeposit)

  const dispatch = useDispatch()

  const totalFee = order.totalOrderAmount

  const deposit = order.totalDepositAmount

  const changePaymentVariant = value => {
    dispatch(setIsDeposit(!!Number(value)))
  }

  const paymentVariants = useMemo(
    () =>
      order?.totalDepositAmount && order?.totalOrderAmount
        ? [
            { label: 'Pay total', price: order?.totalOrderAmount || '0', value: 0 },
            { label: 'Pay deposit', price: order?.totalDepositAmount, value: 1 }
          ]
        : [{ label: 'Pay total', price: order?.totalOrderAmount || '0', value: 0 }],
    [order?.totalDepositAmount, order?.totalOrderAmount]
  )

  useEffect(() => {
    if (paymentVariants.length === 1) {
      dispatch(setIsDeposit(false))
    }
  }, [paymentVariants, dispatch])

  if (!productOrders) return null

  return (
    <PriceBreakdownStyled>
      <Box className='price-breakdown'>
        {!isAccordion && <Typography component='h4'>Price Breakdown</Typography>}
        <Box className='price-breakdown-card'>
          <Discount isAccordion={isAccordion} />

          <ul>
            {productOrders.map(productOrder => (
              <Fragment key={productOrder.id}>
                {productOrder?.subject?.title &&
                  productOrder?.product?.taxonomy?.age?.maxAge &&
                  productOrder?.location?.title && (
                    <li className='first'>
                      <Typography>
                        {productOrder.subject.title.replace(/&amp;/g, '&')} |{' '}
                        {productOrder?.product?.taxonomy?.age?.minAge}-{productOrder?.product?.taxonomy?.age?.maxAge} (
                        {productOrder.location.title})
                      </Typography>
                      {productOrder?.price?.programFee && (
                        <Typography component='span'>
                          £{new Intl.NumberFormat('en-IN').format(productOrder.price.programFee)}
                        </Typography>
                      )}
                    </li>
                  )}

                {productOrder?.price?.couponDiscount && (
                  <li>
                    <Typography>Code: {productOrder?.price?.couponDiscount?.title}</Typography>
                    <Typography component='span' className='red'>
                      -£
                      {new Intl.NumberFormat('en-IN').format(productOrder?.price?.couponDiscount.amount || 0)}
                    </Typography>
                  </li>
                )}

                {(productOrder?.transferFrom || productOrder?.transferTo) && (
                  <li>
                    <Typography>
                      Airport transfer (
                      {
                        transferOptions.find(
                          option =>
                            option?.transferFrom === productOrder?.transferFrom &&
                            option?.transferTo === productOrder?.transferTo
                        ).label
                      }
                      )
                    </Typography>
                    {(productOrder?.transferFrom !== false || productOrder?.transferTo !== false) && (
                      <Typography>
                        £{(productOrder?.price?.transferTo || 0) + (productOrder?.price?.transferFrom || 0)}
                      </Typography>
                    )}
                  </li>
                )}

                {productOrder?.childProductOrder && (
                  <li>
                    <Typography>
                      {productOrder?.childProductOrder?.subject?.title.replace(/&amp;/g, '&')} |{' '}
                      {productOrder?.age?.minAge}-{productOrder?.childProductOrder?.age?.maxAge} (
                      {productOrder?.childProductOrder?.location?.title})
                    </Typography>
                    <Typography component='span'>
                      £{new Intl.NumberFormat('en-IN').format(productOrder?.childProductOrder?.price?.programFee)}
                    </Typography>
                  </li>
                )}

                {productOrder?.childProductOrder?.price?.couponDiscount && (
                  <li>
                    <Typography>Code: {productOrder?.childProductOrder?.price?.couponDiscount?.title}</Typography>
                    <Typography component='span' className='red'>
                      -£
                      {new Intl.NumberFormat('en-IN').format(
                        productOrder?.childProductOrder?.price?.couponDiscount?.amount || 0
                      )}
                    </Typography>
                  </li>
                )}

                {(productOrder?.childProductOrder?.transferFrom || productOrder?.childProductOrder?.transferTo) && (
                  <li>
                    <Typography>
                      Airport transfer (
                      {
                        transferOptions.find(
                          option =>
                            option.transferFrom === productOrder?.childProductOrder.transferFrom &&
                            option.transferTo === productOrder?.childProductOrder.transferTo
                        ).label
                      }
                      )
                    </Typography>
                    {(productOrder?.childProductOrder.transferFrom !== false ||
                      productOrder?.childProductOrder.transferTo !== false) && (
                      <Typography>
                        £
                        {
                          transferOptions.find(
                            option =>
                              option.transferFrom === productOrder?.childProductOrder.transferFrom &&
                              option.transferTo === productOrder?.childProductOrder.transferTo
                          ).price
                        }
                      </Typography>
                    )}
                  </li>
                )}

                {(productOrder?.price?.programDiscount || productOrder?.childProductOrder?.price?.programDiscount) && (
                  <li>
                    <Typography>Programme discount</Typography>
                    <Typography component='span' className='red'>
                      -£
                      {new Intl.NumberFormat('en-IN').format(
                        (productOrder?.price?.programDiscount || 0) +
                          (productOrder?.childProductOrder?.price?.programDiscount || 0)
                      )}
                    </Typography>
                  </li>
                )}

                {!!productOrder?.price?.subtotal && (
                  <li className='subtotal'>
                    <Typography className='bold'>Subtotal</Typography>
                    <Typography component='span' className='bold'>
                      £
                      {new Intl.NumberFormat('en-IN').format(
                        productOrder.price.subtotal + (productOrder?.childProductOrder?.price?.subtotal || 0)
                      )}
                    </Typography>
                  </li>
                )}
              </Fragment>
            ))}

            <li className='total'>
              <Typography className='total'>{isDeposit ? 'Deposit' : 'Total fee'}</Typography>
              <Typography component='span' className='total'>
                £{new Intl.NumberFormat('en-IN').format(isDeposit ? deposit : totalFee)}
              </Typography>
            </li>
          </ul>
        </Box>
        {!isAccordion && (
          <div className='radio-buttons'>
            <RadioButtonGroup
              className='payment-variants'
              isColumn={true}
              isSmall={true}
              name='paymentVariant'
              value={`${+isDeposit}`}
              groupName='paymentVariants'
              options={paymentVariants}
              onChange={e => changePaymentVariant(e.target.value)}
            />

            {!!order?.totalDepositAmount && !!order?.totalOrderAmount && (
              <CustomTooltip
                placement='left-start'
                width={'402'}
                title={
                  <>
                    <Typography className='tooltip-header' component='h3'>
                      Deposit options
                    </Typography>

                    <Typography className='tooltip-text'>
                      <span style={{ fontWeight: '700' }} className='tooltip-span'>
                        £995
                      </span>{' '}
                      deposit for all in-person and online research programmes <br />
                      <span style={{ fontWeight: '700' }} className='tooltip-span'>
                        £695
                      </span>{' '}
                      deposit for online insight programmes (excluding intensive options)
                      <br />
                      <br />
                      Remaining balance is due by 12 weeks prior to programme as highlighted in terms and conditions.
                    </Typography>
                  </>
                }
              >
                <Box component='span' className='tooltip'>
                  <InfoIcon />
                </Box>
              </CustomTooltip>
            )}
          </div>
        )}
      </Box>
    </PriceBreakdownStyled>
  )
}

export default PriceBreakdown
