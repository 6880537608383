import { SvgIcon } from '@mui/material'

const CircleIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 24, height: 24, fill: 'none !important', ...sx }} {...props} viewBox='0 0 24 24'>
      <circle cx='12' cy='12' r='11' stroke='#D5DADF' strokeWidth='2' />
    </SvgIcon>
  )
}

export default CircleIcon
