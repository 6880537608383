import { SvgIcon } from '@mui/material'

const SuccessGreenIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 24, height: 24, fill: 'none !important', ...sx }} {...props} viewBox='0 0 24 24'>
      <circle cx='12' cy='12' r='10' fill='#18AB56' />
      <path d='M7 11.9993L10.3333 15.3327L17 8.66602' stroke='white' strokeWidth='2.5' />
    </SvgIcon>
  )
}

export default SuccessGreenIcon
