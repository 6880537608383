import cn from 'classnames'
import { useLocation } from 'react-router'

import CheckmarkIcon from '../../assets/icons/CheckmarkIcon'
import { selectCompletedFields, selectStartPathwayScreen } from '../../store/enrolment/enrolment.selectors'
import { progressConfig } from './progress.config'
import { ProgressList } from './styledProgressbar'

import { useSelector } from 'react-redux'

const Progressbar = () => {
  const { pathname } = useLocation()

  const startPathwayScreen = useSelector(selectStartPathwayScreen)

  const completedFields = {
    ...useSelector(selectCompletedFields),
    programSummary: pathname === '/payment' || pathname === '/success',
    payment: pathname === '/success'
  }

  const getIsActiveSubItem = (field, path) => {
    if (field === 'programOptions') {
      return pathname === path && !startPathwayScreen
    }
    return path === pathname
  }

  const getIsCompletedSubItem = (field, path) => {
    if (field === 'programOptions') {
      return pathname === path
        ? startPathwayScreen && completedFields[field]
        : path !== pathname && completedFields[field]
    }
    return path !== pathname && completedFields[field]
  }

  return (
    <ProgressList>
      {progressConfig.map(({ id, text, field, subList, subtext, paths, childrenPaths }, key) => (
        <li
          key={id}
          className={cn('progress-item', {
            'progress-item-active': childrenPaths.includes(pathname),
            'progress-item-completed':
              (!childrenPaths.includes(pathname) && subList && subList.every(({ field }) => completedFields[field])) ||
              pathname === '/success'
          })}
        >
          <p className='progress-item-text'>
            {(!childrenPaths.includes(pathname) && subList && subList.every(({ field }) => completedFields[field])) ||
            pathname === '/success' ? (
              <CheckmarkIcon />
            ) : (
              <span className='progress-item-marker'>{key + 1}</span>
            )}
            {text}
          </p>
          {subtext && <p className='progress-item-subtext'>{subtext}</p>}

          <div key={id} className='progress-item-sub'>
            <ul className='progress-item-sub-list'>
              {paths.includes(pathname) &&
                subList.map(({ text, path, field }) => (
                  <li
                    key={field}
                    className={cn('progress-item-sub-list-item', {
                      'progress-item-sub-list-item-active': getIsActiveSubItem(field, path),
                      'progress-item-sub-list-item-completed':
                        getIsCompletedSubItem(field, path) || pathname === '/success'
                    })}
                  >
                    {getIsCompletedSubItem(field, path) || pathname === '/success' ? <CheckmarkIcon /> : <span></span>}
                    {text}
                  </li>
                ))}
            </ul>
          </div>
        </li>
      ))}
    </ProgressList>
  )
}

export default Progressbar
