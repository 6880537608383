import { SvgIcon } from '@mui/material'

const AlertGoldIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20, fill: 'none !important', ...sx }} {...props} viewBox='0 0 20 20'>
      <path
        d='M9.9974 1.66699C7.7474 1.66699 5.66406 2.58366 4.08073 4.16699C2.4974 5.75033 1.66406 7.91699 1.66406 10.167C1.66406 14.667 5.33073 18.3337 9.83073 18.3337H9.9974C14.6632 18.2503 18.3307 14.5003 18.3307 9.83366C18.3307 5.33366 14.6632 1.66699 10.1641 1.66699H9.9974ZM9.33073 6.31708C9.33073 5.90041 9.66406 5.65041 9.9974 5.65041C10.4141 5.65041 10.6641 5.98374 10.6641 6.31708V10.4837C10.6641 10.9004 10.3307 11.1504 9.9974 11.1504C9.58073 11.1504 9.33073 10.9004 9.33073 10.4837V6.31708ZM9.99723 14.3588C9.39892 14.3588 8.9139 13.8738 8.9139 13.2755C8.9139 12.6772 9.39892 12.1922 9.99723 12.1922C10.5955 12.1922 11.0806 12.6772 11.0806 13.2755C11.0806 13.8738 10.5955 14.3588 9.99723 14.3588Z'
        fill='#BB945C'
      />
    </SvgIcon>
  )
}

export default AlertGoldIcon
