import { SvgIcon } from '@mui/material'

const ShieldedCheckmarkIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', width: '24px', height: '24px', ...sx }}
      viewBox='0 0 24 24'
      fill='none'
      {...props}>
      <path d='M12 23C12 23 21 18.6 21 12V4.3L12 1L3 4.3V12C3 18.6 12 23 12 23Z' fill='#BB945C' />
      <path d='M8 11.1429L10.9091 14L16 9' stroke='white' strokeWidth='2' />
    </SvgIcon>
  )
}

export default ShieldedCheckmarkIcon
