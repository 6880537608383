import { configureStore } from '@reduxjs/toolkit'
import applicationSlice from './application/application.slice'
import enrolmentSlice from './enrolment/enrolment.slice'

export const store = configureStore({
  reducer: {
    enrolment: enrolmentSlice,
    application: applicationSlice,
  },
})
