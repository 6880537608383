import { Box } from '@mui/material'

import styled from 'styled-components'

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-right: 32px;

  @media (max-width: 1024px) {
    padding-right: 0px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #dadada;

    border: 4px solid #dadada;

    border-radius: 99999px;
  }

  .title {
    ${({ theme }) => theme.typography.h1};
    font-weight: 600;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .subtitle {
    color: #1d1d1d;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
  }

  .block {
    margin-top: 16px;
    width: 100%;
  }

  .box {
    width: 100%;
    margin-top: 16px;
  }

  .buttons-block {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 24px 32px 24px 0;
    background: rgba(255, 255, 255, 0.8);

    @media (max-width: 1024px) {
      position: fixed;
      z-index: 100;
      bottom: 0;
      left: 0px;
      right: 0px;
      flex-direction: column-reverse;
      padding: 24px;

      div {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }

  .summary {
    &-box {
      border-radius: 16px;
      border: 1px solid #d5dadf;

      &-section {
        position: relative;
        padding: 24px 16px 16px 16px;
      }
    }

    &-details-container {
      display: flex;
    }

    .product {
      &-location {
        position: absolute;
        top: 0;
        right: 16px;
        padding: 2px 11px;
        border-radius: 0px 0px 8px 8px;
        background: ${({ color }) => (color ? `#${color}` : '#fdb717')};
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        color: #ffffff;
      }

      &-details {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &-ages {
        width: 99px;
        margin-left: 12px;
        padding: 7px 16px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 450;
        color: #6f6f6f;
        background-color: #f5f5f7;
        border-radius: 100px;
      }

      &-image {
        width: 76px;
        height: 76px;
        border-radius: 12px;
      }

      &-course-name {
        margin-left: 16px;
        color: #1d1d1d;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      &-delete-button {
        padding: 0;
        margin-right: 12px;

        &:hover {
          background-color: transparent;
        }
      }

      &-change {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 4px 16px;
        cursor: pointer;

        svg {
          transition: transform 1.5s ease-in-out;
        }

        &:hover {
          svg {
            transform: rotate(360deg);
          }
        }

        &-text {
          font-weight: 450;
          font-size: 14px;
          line-height: 20px;
          color: #2d4960;
        }
      }

      &-general-info {
        padding: 16px;
        margin-top: 16px;
        border-radius: 16px;
        background: #f1eee9;
      }

      &-program-type {
        font-size: 16px;
        font-weight: 500;
        line-height: 132%;
        color: #181c2a;
      }

      &-features {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
      }

      &-program {
        color: #6f6f6f;
        font-size: 14px;
        font-weight: 450;
        line-height: 130%;
      }

      &-dates {
        color: #1d1d1d;
        font-size: 14px;
        font-weight: 300;
        line-height: 130%;
      }

      &-divider {
        width: 4px;
        height: 4px;
        background-color: #bb945c;
        border-radius: 100px;

        margin: 0 12px;
      }
    }
  }

  .details {
    padding: 16px 24px;
    border-radius: 16px;
    border: 1px solid #d5dadf;
    background: #fff;

    &-section {
      padding: 8px 0;
      border-bottom: 1px solid #f0f0f1;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .header {
          color: #1d1d1d;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;

          @media (max-width: 1024px) {
            display: block;
          }
        }

        .edit {
          color: #2d4960;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          cursor: pointer;
        }
      }

      &-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 16px;

        margin-bottom: 2px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        .col-left {
          color: #6f6f6f;

          font-size: 14px;
          font-weight: 450;
          line-height: 24px;
        }

        .col-right {
          color: #1d1d1d;

          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
        }

        .empty {
          font-weight: 450;
        }

        .phone {
          display: flex;
          align-items: center;
          font-weight: 450;

          img {
            margin-right: 4px;
          }
        }
      }
    }
  }

  .following {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 0;

    &-section {
      margin-top: 16px;
    }

    &-title {
      color: #6f6f6f;
      font-size: 14px;
      font-weight: 300;
      line-height: 140%;
    }

    &-text {
      color: #6f6f6f;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
    }
  }

  .accordion {
    margin-top: 16px;
    border: 1px solid #d5dadf;
    border-radius: 16px;
    padding: 0;

    & .MuiCollapse-root {
      border-top: 1px solid #d5dadf;
    }
  }

  .accordion-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;

    &.Mui-expanded {
      height: 48px;
      min-height: 48px;
    }
  }

  .accordion-title {
    padding-left: 12px;
    color: #2d4960;
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;

    &-price {
      color: #1d1d1d;
    }
  }

  .accordion-details {
    padding: 0;
  }

  .bordered-discount {
    border: 1px solid #d5dadf;
    border-radius: 8px;
    padding: 12px 16px;
  }
`
