import styled from 'styled-components'

export const StyledForm = styled.form`
  margin-top: 1.6rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.4rem;

  @media (max-width: 1024px) {
    gap: 16px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &.isGap {
      gap: 16px;
    }
  }

  .form-footer {
    display: flex;
    align-items: center;
    background-color: #fff;
    gap: 16px;
    padding: 24px 0;

    @media (max-width: 768px) {
      justify-content: center;
      padding: 0 3.2rem;
      right: 0;
      left: 0;
    }

    @media (max-width: 480px) {
      justify-content: center;
      padding: 0 1.6rem;
      right: 0;
      left: 0;
    }

    &-text {
      max-width: 46rem;
      ${({ theme }) => theme.typography.textSmall};
      line-height: 1.56rem;

      @media (max-width: 1200px) {
        width: 30rem;
      }
      @media (max-width: 1024px) {
        width: 36rem;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 1.6rem;
    }

    &.isButtonSticky {
      @media (max-width: 1024px) {
        position: fixed;
        bottom: 0;
        z-index: 100;
        padding-top: 0.8rem;
        padding-bottom: 4.2rem;
        margin-bottom: env(safe-area-inset-bottom);

        background: rgba(255, 255, 255, 0.8);
      }
    }
  }
`
