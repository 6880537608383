import styled from 'styled-components'

export const Aside = styled.aside`
  min-height: 100vh;
  max-height: 100vh;
  min-width: 34.8rem;

  padding: 8px 0 8px 8px;

  @media (max-width: 1376px) {
    min-width: 30.8rem;
  }

  @media (max-width: 1182px) {
    min-width: 26.8rem;
  }

  @media (max-width: 1024px) {
    display: none;
  }

  .sidebar-wrapper {
    position: fixed;
    min-width: 34rem;
    max-width: 34rem;
    min-height: 98vh;
    max-height: 98vh;
    padding: 1.6rem 2.4rem 1rem;
    background-color: ${({ theme }) => theme.palette.main.lightGray};
    border-radius: 2.4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1376px) {
      min-width: 30rem;
      max-width: 30rem;
    }

    @media (max-width: 1182px) {
      min-width: 26rem;
      max-width: 26rem;
      padding: 2.4rem 1.6rem 3.2rem;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .navigation {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 1.6rem;
    padding: 0;
    margin-bottom: 4rem;

    @media (max-height: 668px) {
      margin-bottom: 2rem;
    }

    @media (max-height: 578px) {
      gap: 0.8rem;
    }

    @media (max-height: 550px) {
      margin-bottom: 1rem;
    }

    li {
      padding: 0;
      ${({ theme }) => theme.typography.sidebar.navigation};
    }
  }

  .sidebar-footer {
    margin-top: 4rem;
    padding-left: 0.4rem;

    @media (max-height: 668px) {
      margin-top: 2rem;
    }

    @media (max-height: 550px) {
      margin-top: 1rem;
    }

    &-icons {
      display: flex;
      align-items: center;
      gap: 1.6rem;
      height: 26px;

      &-determiner {
        height: 1.6rem;
        width: 0.1rem;
        ${({ theme }) => `background-color: ${theme.palette.main.grayBlue}`};
        line-height: 2.4rem;
      }

      &-achieve {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        ${({ theme }) => theme.typography.sidebar.navigation};
      }
    }

    &-subtext {
      margin-top: 4rem;
      ${({ theme }) => theme.typography.sidebar.subtext};

      @media (max-height: 578px) {
        margin-top: 0.4rem;
      }
    }
  }
`
