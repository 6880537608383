import { Box } from '@mui/material'

import styled from 'styled-components'

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-right: 32px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #dadada;

    border: 4px solid #dadada;

    border-radius: 99999px;
  }

  @media (max-width: 1024px) {
    padding-right: 0px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .title {
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
    color: #1d1d1d;

    @media (max-width: 1024px) {
      margin-top: 6px;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      line-height: 28.6px;
      color: #1d1d1d;
    }
  }

  .title-gold {
    color: #bb945c;
  }

  .list-subheader {
    color: red;

    & .MuiListSubheader-root {
      color: red;
    }
  }

  .button {
    color: #ffffff;
    padding: 14px 28px 14px 32px;
    border-radius: 52px;
    min-width: 0;
    background-color: #181c2a;

    &.Mui-disabled {
      background-color: rgba(24, 28, 42, 0.3);
    }
  }

  .form-footer-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    color: #6f6f6f;
  }

  .education-programme-box {
    display: flex;
    flex-direction: column;
  }

  .education-subtitle {
    margin-top: 24px;
    font-size: 28px;
    font-weight: 500;
    line-height: 39.2px;
    color: #1d1d1d;

    @media (max-width: 1024px) {
      margin-top: 16px;
      font-size: 22px;
      line-height: 28px;
    }
  }

  .education-text-wrapper {
    display: flex;
    margin-top: 8px;
    justify-content: flex-start;
  }

  .education-text {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: #1d1d1d;
  }

  .education-text-gold {
    color: #bb945c;
    font-weight: 450;
  }

  .calendar-label {
    ${({ theme }) => theme.typography.textSecondary};
    line-height: 21px;
    white-space: initial;
    margin-bottom: 0.8rem;
  }

  .calendar-label-grey {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: #8c8c8c;
  }

  .toaster {
    width: 100%;
    background-color: #e8f7ee;
    border-radius: 4px;
    padding: 8px 16px 8px 16px;

    & div[role='status'] {
      justify-content: flex-start;
    }
  }

  .toast-text {
    color: #1d1d1d;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  .accordion {
    margin-top: 16px;
    border: 1px solid #d5dadf;
    border-radius: 16px;
    padding: 0;

    & .MuiCollapse-root {
      border-top: 1px solid #d5dadf;
    }
  }

  .accordion-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    height: 48px;

    &.Mui-expanded {
      height: 48px;
      min-height: 48px;
    }
  }

  .accordion-title {
    padding-left: 12px;
    color: #2d4960;
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
  }

  .accordion-details {
    padding: 0;
  }

  .bordered-discount {
    border: 1px solid #d5dadf;
    border-radius: 8px;
    padding: 12px 16px;
  }
`

export const InnerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 400px);

  @media (max-width: 1024px) {
    height: auto;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #dadada;

    border: 4px solid #dadada;

    border-radius: 99999px;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 0;
    position: relative;

    & .form-container {
      gap: 14px;
    }

    & .form-footer {
      position: sticky;
      bottom: 0;

      @media (max-width: 1024px) {
        position: fixed;
        bottom: 0;
      }
    }
  }

  .button-add {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .form-footer-button {
    &.Mui-disabled {
      color: #ffffff;
      background-color: rgba(24, 28, 42, 0.3);
    }
  }

  .toaster {
    width: 100%;
    background-color: #e8f7ee;
    border-radius: 4px;
    padding: 8px 16px 8px 16px;

    & div[role='status'] {
      justify-content: flex-start;
    }
  }

  .toast-text {
    color: #1d1d1d;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
`
