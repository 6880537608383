import axios from 'axios'

import { baseUrl } from '../configs/axios'

export const axiosClassic = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosClassic.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)
