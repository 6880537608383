import styled from 'styled-components'

export const Wrapper = styled('div')`
  display: flex;
  height: 100%;
  overflow: auto;
  gap: 64px;
  color: #1d1d1d;
  padding-bottom: 25px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #dadada;

    border: 4px solid #dadada;

    border-radius: 99999px;
  }

  .image {
    width: 100%;
    height: 100px;
    background-color: #181c2a;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  h1 {
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 16px;

    @media (max-width: 1024px) {
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 28.6px;
      margin-top: 16px;
      text-align: center;
    }
  }

  .description,
  p span {
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 150%;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
    margin-bottom: 4px;
  }

  .expiry-date {
    font-size: 16px;
  }

  p a {
    color: var(--Gold, #bb945c);
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
  }

  hr {
    width: 100%;
    border: 0.5px solid #dadada;
    margin: 28.5px 0;
  }

  hr:last-of-type {
    margin-bottom: 32px;
  }

  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 4px;
    padding-top: 32px;

    @media (max-width: 1024px) {
      padding-top: 16px;
      text-align: center;
    }
  }

  img {
    height: 348px;
    flex: 1 0 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    padding-bottom: 32px;

    @media (max-width: 1024px) {
      padding-bottom: 16px;
    }
  }

  ul li {
    display: flex;
    gap: 16px;
    padding: 12px 0;

    @media (max-width: 1024px) {
      padding: 8px 0;
    }
  }

  ul li p {
    margin: 0;
  }

  ul li svg {
    width: 24px;
    height: 24px;
  }

  ul li:nth-child(2) svg {
    padding: 1px;
  }

  ul li p {
    color: #6f6f6f;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
  }
  button {
    padding-right: 30px;
  }

  @media (max-width: 1323px) {
    padding: 20px 0 25px;
  }

  @media (max-width: 1116px) {
    padding: 40px 0 25px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 32px;
  }

  .link {
    @media (max-width: 1024px) {
      position: fixed;
      padding-right: 32px;
      width: 100%;
      bottom: 0;
      z-index: 100;
      padding-top: 0.8rem;
      padding-bottom: 4.2rem;
      margin-bottom: env(safe-area-inset-bottom);

      background: rgba(255, 255, 255, 0.8);
    }
  }

  .error-box {
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 128px;
    }
  }
`
