import { Link } from 'react-router-dom'

import { Box, List, ListItem, Typography } from '@mui/material'

import Logo from '../../assets/icons/Logo'
import StarAchieveIcon from '../../assets/icons/StarAchieve'
import TruspilotIcon from '../../assets/icons/TruspilotIcon'
import Progressbar from './Progressbar'
import { Aside } from './styled'

const Sidebar = props => {
  return (
    <Aside {...props}>
      <Box className='sidebar-wrapper'>
        <Box className='sidebar-content'>
          <Logo sx={{ width: '137px', height: '40px' }} />

          <Progressbar />
        </Box>

        <Box className='sidebar-footer'>
          <List className='navigation'>
            <a href='https://help.immerse.education/' target='_blank' rel='noreferrer'>
              <ListItem>Help</ListItem>
            </a>
            <a href='https://www.immerse.education/terms-conditions/' target='_blank' rel='noreferrer'>
              <ListItem>Terms and Conditions</ListItem>
            </a>
            <a href='https://www.immerse.education/privacy-policy' target='_blank' rel='noreferrer'>
              <ListItem>Privacy Policy</ListItem>
            </a>
          </List>
          {/* <Box className='sidebar-footer-icons'>
            <Link href='/'>
              <TruspilotIcon />
            </Link>

            <Box className='sidebar-footer-icons-determiner'></Box>

            <Link href='/' className='sidebar-footer-icons-achieve'>
              <StarAchieveIcon /> 4.9
            </Link>
          </Box> */}
          <Typography className='sidebar-footer-subtext'>© {new Date().getFullYear()} Immerse Education</Typography>
        </Box>
      </Box>
    </Aside>
  )
}

export default Sidebar
