import { SvgIcon } from '@mui/material'

const CrossMarkIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 24, height: 24, fill: 'none', ...sx }} {...props} viewBox='0 0 24 24'>
      <path
        d='M18 6L6 18'
        stroke='#2D4960'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 6L18 18'
        stroke='#2D4960'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default CrossMarkIcon
