import { SvgIcon } from '@mui/material'

const ChevronLeft = ({ sx = {}, fill, ...props }) => {
  return (
    <SvgIcon sx={{ fill: 'none !important', width: 9, height: 15, ...sx }} {...props} viewBox='0 0 9 15'>
      <path
        d='M7.79014 14.1092C7.88387 14.0163 7.95826 13.9057 8.00903 13.7838C8.0598 13.6619 8.08594 13.5312 8.08594 13.3992C8.08594 13.2672 8.0598 13.1365 8.00903 13.0147C7.95826 12.8928 7.88387 12.7822 7.79014 12.6892L3.21015 8.10923C3.11642 8.01627 3.04203 7.90567 2.99126 7.78381C2.94049 7.66195 2.91435 7.53124 2.91435 7.39923C2.91435 7.26722 2.94049 7.13651 2.99126 7.01465C3.04203 6.89279 3.11642 6.78219 3.21015 6.68923L7.79014 2.10923C7.88387 2.01627 7.95826 1.90567 8.00903 1.78381C8.0598 1.66195 8.08594 1.53124 8.08594 1.39923C8.08594 1.26722 8.0598 1.13651 8.00903 1.01465C7.95826 0.892794 7.88387 0.782193 7.79014 0.68923C7.60278 0.502979 7.34933 0.398437 7.08514 0.398437C6.82096 0.398437 6.5675 0.502979 6.38014 0.68923L1.79015 5.27923C1.22835 5.84173 0.912793 6.60423 0.912793 7.39923C0.912793 8.19423 1.22835 8.95673 1.79015 9.51923L6.38014 14.1092C6.5675 14.2955 6.82096 14.4 7.08514 14.4C7.34933 14.4 7.60278 14.2955 7.79014 14.1092Z'
        fill={fill}
      />
    </SvgIcon>
  )
}

export default ChevronLeft
