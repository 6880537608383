import { Box } from '@mui/material'

import styled from 'styled-components'

export const StyledButtonContainer = styled(Box)`
  .button-light {
    color: ${({ theme }) => theme.palette.text.black};
    background-color: ${({ theme }) => theme.palette.main.lightGray};

    & > svg {
      path {
        stroke: ${({ theme }) => theme.palette.text.black};
        fill: ${({ theme }) => theme.palette.text.black};
      }
    }

    &:hover {
      background-color: #f0f0f1;
      box-shadow: none;

      & > svg {
        path {
          stroke: ${({ theme }) => theme.palette.main.white};
          fill: ${({ theme }) => theme.palette.text.black};
        }
      }
    }
  }
`
