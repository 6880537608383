import cn from 'classnames'
import parsePhoneNumber, { isValidNumber } from 'libphonenumber-js'
import React, { useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery } from '@mui/material'

import ArrowRight from '../../assets/icons/ArrowRight'
import ListIcon from '../../assets/icons/ListIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import CustomButton from '../../components/Button'
import Form from '../../components/Form'
import PhoneInput from '../../components/PhoneInput'
import Select from '../../components/Select'
import Summary from '../../components/Summary'
import Input from '../../components/TextField'
import Toast from '../../components/Toast'
import { DAYS, MONTHS, YEARS } from '../../constants/participantDetailsData'
import { emailRegex } from '../../helpers/validate'
import { addParentGuardianDetails } from '../../store/enrolment/enrolment.actions'
import { selectMainMainOrderId, selectOrder, selectStudentDetails } from '../../store/enrolment/enrolment.selectors'
import { formatNumberWithLeadingZero, monthMap, monthsNames } from '../../utils/dateToNumber'
import { Wrapper } from './styled'

import { useDispatch, useSelector } from 'react-redux'

const ParentGuardianDetailsScreen = () => {
  const navigate = useNavigate()

  const [params] = useSearchParams()
  const dispatch = useDispatch()

  const currentStudentDetails = useSelector(selectStudentDetails)
  const order = useSelector(selectOrder)
  const orderId = useSelector(selectMainMainOrderId)

  const [isLoading, setIsLoading] = useState(false)

  const parsedPhoneNumber = parsePhoneNumber(currentStudentDetails.parentPhoneNumber || '')

  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const initialState = useMemo(
    () => ({
      ...currentStudentDetails,
      siblingDayOfBirth: currentStudentDetails.siblingDateOfBirth
        ? formatNumberWithLeadingZero(new Date(currentStudentDetails.siblingDateOfBirth).getDate())
        : '',

      siblingMonthOfBirth: currentStudentDetails.siblingDateOfBirth
        ? monthsNames[new Date(currentStudentDetails.siblingDateOfBirth).getMonth()]
        : '',
      siblingYearOfBirth: currentStudentDetails.siblingDateOfBirth
        ? new Date(currentStudentDetails.siblingDateOfBirth).getFullYear()
        : '',
      phoneCode: parsedPhoneNumber ? `+${parsedPhoneNumber.countryCallingCode}` : '+44',
      phoneNumber: parsedPhoneNumber ? currentStudentDetails.parentPhoneNumber : '',
      phoneBody: parsedPhoneNumber ? `${parsedPhoneNumber.nationalNumber}` : '',
      siblingEmail: currentStudentDetails.siblingEmail || '',
      siblingLastName: currentStudentDetails.siblingLastName || ''
    }),
    [currentStudentDetails]
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields, errors }
  } = useForm({ defaultValues: initialState })

  const { field: firstName } = useController({
    name: 'parentFirstName',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      minLength: { value: 2, message: 'Enter a valid name' }
    }
  })

  const { field: lastName } = useController({
    name: 'parentLastName',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      minLength: { value: 2, message: 'Enter a valid name' }
    }
  })

  const { field: email } = useController({
    name: 'parentEmail',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      pattern: {
        value: emailRegex,
        message: 'Invalid email address'
      }
    }
  })

  const { field: phoneCode } = useController({
    name: 'phoneCode',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: phoneBody } = useController({
    name: 'phoneBody',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: phoneNumber } = useController({
    name: 'phoneNumber',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      validate: value => isValidNumber(value)
    }
  })

  const { field: siblingLastName } = useController({
    name: 'siblingLastName',
    control: control
  })

  const { field: siblingFirstName } = useController({
    name: 'siblingFirstName',
    control: control
  })

  const { field: siblingEmail } = useController({
    name: 'siblingEmail',
    control: control,
    rules: {
      pattern: {
        value: emailRegex,
        message: 'Invalid email address'
      }
    }
  })

  const { field: siblingDayOfBirth } = useController({
    name: 'siblingDayOfBirth',
    control: control
  })

  const { field: siblingMonthOfBirth } = useController({
    name: 'siblingMonthOfBirth',
    control: control
  })

  const { field: siblingYearOfBirth } = useController({
    name: 'siblingYearOfBirth',
    control: control
  })

  const changePhoneCodeHandler = code => {
    phoneCode.onChange(code)
    phoneNumber.onChange(`${code}${phoneBody.value}`)
  }

  const changePhoneBodyHandler = code => {
    const value = code.target.value
    phoneBody.onChange(value)
    phoneNumber.onChange(`${phoneCode.value}${value}`)
  }

  const onSubmit = data => {
    setIsLoading(true)

    const paramsUrl = params.get('navigate')
    const navigateUrl = paramsUrl ? `/${paramsUrl}` : `/summary`
    const { siblingDayOfBirth, siblingMonthOfBirth, siblingYearOfBirth, phoneNumber, parentEmail, ...other } = data

    const month = monthMap[siblingMonthOfBirth]

    const details = {
      ...other,
      parentEmail: parentEmail.trim(),
      parentPhoneNumber: phoneNumber,
      ...(siblingYearOfBirth &&
        siblingMonthOfBirth &&
        siblingDayOfBirth && {
          siblingDateOfBirth: `${siblingYearOfBirth}-${month}-${siblingDayOfBirth}`
        })
    }

    if (isDirty) {
      dispatch(
        addParentGuardianDetails({
          orderId,
          body: Object.entries(details).reduce((acc, curr) => {
            if (curr[1]) {
              acc[curr[0]] = curr[1]
            }
            return acc
          }, {}),
          callback: () => {
            setIsLoading(false)

            navigate(navigateUrl)
          },
          callbackRejected: () => setIsLoading(false)
        })
      )
    } else {
      navigate(navigateUrl)
    }
  }

  const dateOfBirthError =
    errors?.siblingDayOfBirth?.message || errors?.siblingMonthOfBirth?.message || errors?.siblingYearOfBirth?.message

  const phoneNumberError =
    errors?.phoneNumber?.message || errors?.phoneBody?.message
      ? 'This field is required'
      : errors?.phoneNumber?.type === 'validate'
        ? 'Please enter a valid phone number'
        : ''

  const isPhoneNumberError =
    !(!dirtyFields?.phoneNumber && !dirtyFields?.phoneCode && !dirtyFields?.phoneBody) && !!phoneNumberError

  return (
    <Wrapper>
      {isSmallScreen && (
        <Accordion
          className='accordion'
          disableGutters
          elevation={0}
          sx={{
            '&.Mui-expanded:first-of-type': {
              marginTop: '16px'
            }
          }}
        >
          <AccordionSummary expandIcon={<SelectAngleIcon />} className='accordion-summary'>
            <ListIcon />
            <Typography className='accordion-title'>Summary</Typography>

            <Typography className='accordion-title accordion-title-price'>
              £{new Intl.NumberFormat('en-IN').format(order.totalOrderAmount)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            <Summary />
          </AccordionDetails>
        </Accordion>
      )}

      <Typography className='title' component='h1'>
        Parent/Guardian Details
      </Typography>

      <Form
        className='form'
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <>
            <CustomButton isLight onClick={() => navigate(-1)}>
              Back
            </CustomButton>

            <CustomButton type='submit' disabled={isLoading}>
              Next <ArrowRight />
            </CustomButton>
          </>
        }
      >
        <Box className='form-col'>
          <Input
            value={firstName.value}
            onChange={firstName.onChange}
            error={errors?.parentFirstName?.message}
            containerClass='form-item'
            label='Parent/Guardian first name'
            placeholder='Enter first name'
          />

          <Input
            value={lastName.value}
            onChange={lastName.onChange}
            error={errors?.parentLastName?.message}
            containerClass='form-item'
            label='Parent/Guardian last name'
            placeholder='Enter last name'
          />

          <Input
            value={email.value}
            onChange={email.onChange}
            error={errors?.parentEmail?.message}
            containerClass='form-item'
            label='Parent/Guardian email address'
            placeholder='Enter email address'
          />

          <Box className='form-item'>
            <Typography
              className={cn('text-field-label', {
                'text-field-label-error': isPhoneNumberError || !!phoneNumberError
              })}
            >
              Parent/Guardian home phone number
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <PhoneInput
                onChange={changePhoneCodeHandler}
                error={isPhoneNumberError || !!phoneNumberError}
                initialValue={phoneCode.value}
                initialCode={parsedPhoneNumber?.country ? parsedPhoneNumber.country : 'GB'}
                sx={{ marginRight: '8px' }}
              />

              <Input
                value={phoneBody.value}
                onChange={changePhoneBodyHandler}
                error={isPhoneNumberError || !!phoneNumberError}
                containerClass='phone-input'
                type='number'
              />
            </Box>

            {(isPhoneNumberError || phoneNumberError) && (
              <Typography
                className='text-field-label text-field-label-message-error'
                sx={{ marginBottom: '0 !important' }}
              >
                {phoneNumberError}
              </Typography>
            )}
          </Box>

          <Box sx={{ marginTop: '24px' }}>
            <Typography component='h2' className='subtitle'>
              Refer a Sibling (optional)
            </Typography>

            <Typography className='text'>
              Does the student have a sibling? Share their details below and you will receive their{' '}
              <span style={{ color: '#BB945C', fontWeight: 450 }}>10% discount</span> code once you complete this
              enrolment.
            </Typography>
          </Box>

          <Input
            value={siblingFirstName.value}
            onChange={siblingFirstName.onChange}
            error={errors?.siblingFirstName?.message}
            containerClass='form-item'
            label='First Name'
            placeholder='Enter first name'
          />

          <Input
            value={siblingLastName.value}
            onChange={siblingLastName.onChange}
            error={errors?.siblingLastName?.message}
            containerClass='form-item'
            label='Last Name'
            placeholder='Enter last name'
          />

          <Box className='form-item'>
            <Typography
              className={cn('text-field-label', {
                'text-field-label-error':
                  errors?.siblingDayOfBirth?.message ||
                  errors?.siblingMonthOfBirth?.message ||
                  errors?.siblingYearOfBirth?.message
              })}
            >
              Date of birth
            </Typography>

            <Box className='form-col-3'>
              <Select
                onChange={siblingDayOfBirth.onChange}
                error={!!errors?.siblingDayOfBirth?.message}
                options={DAYS}
                value={siblingDayOfBirth.value}
                placeholder='DD'
              />

              <Select
                onChange={siblingMonthOfBirth.onChange}
                error={!!errors?.siblingMonthOfBirth?.message}
                options={MONTHS}
                value={siblingMonthOfBirth.value}
                placeholder='MM'
              />

              <Select
                onChange={siblingYearOfBirth.onChange}
                error={!!errors?.siblingYearOfBirth?.message}
                options={YEARS}
                value={siblingYearOfBirth.value}
                placeholder='YYYY'
              />
            </Box>

            {dateOfBirthError && (
              <Typography
                className='text-field-label text-field-label-message-error'
                sx={{ marginBottom: '0 !important' }}
              >
                This field is required
              </Typography>
            )}
          </Box>
        </Box>
      </Form>

      <Toast />
    </Wrapper>
  )
}

export default ParentGuardianDetailsScreen
