import { SvgIcon } from '@mui/material'

const ArrowTopRight = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ fill: 'none !important', width: 24, height: 24, ...sx }} {...props} viewBox='0 0 24 24'>
      <path
        d='M7.54303 16.3842L15.7074 6.91891M15.7074 6.91891L16.3319 15.3812M15.7074 6.91891L7.24515 7.54336'
        stroke='#181C2A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default ArrowTopRight
