import { format } from 'date-fns'
import parsePhoneNumber from 'libphonenumber-js'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material'

import ArrowRight from '../../assets/icons/ArrowRight'
import BookIcon from '../../assets/icons/BookIcon'
import EmailIcon from '../../assets/icons/EmailIcon'
import InfoIcon from '../../assets/icons/InfoIcon'
import InfoStrokeIcon from '../../assets/icons/InfoStrokeIcon'
import ListIcon from '../../assets/icons/ListIcon'
import RefreshIcon from '../../assets/icons/RefreshIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import TrashIcon from '../../assets/icons/TrashIcon'
import CustomButton from '../../components/Button'
import ChangeProgrammeModal from '../../components/ChangeProgrammeModal'
import List from '../../components/List'
import PriceBreakdown from '../../components/PriceBreakdown'
import RemoveModal from '../../components/RemoveModal'
import Summary from '../../components/Summary'
import CustomTooltip from '../../components/Tooltip'
import { createProductOrderBackup, deleteProductOrder } from '../../store/enrolment/enrolment.actions'
import { selectOrder, selectProductOrders, selectStudentDetails } from '../../store/enrolment/enrolment.selectors'
import { transferOptionsValues } from './constants'
import { Wrapper } from './styled'

import { useDispatch, useSelector } from 'react-redux'

const SummaryScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const studentDetails = useSelector(selectStudentDetails)
  const productOrders = useSelector(selectProductOrders)
  const order = useSelector(selectOrder)

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenChangeModal, setIsOpenChangeModal] = useState(false)
  const [productOrderId, setProductOrderId] = useState(null)
  const [productIdForChange, setProductIdForChange] = useState(null)

  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const handleDeleteProductOrder = () => {
    setIsOpenDeleteModal(false)
    if (productOrderId) {
      dispatch(
        deleteProductOrder({
          productOrderId
        })
      )
    }
  }

  const openRemoveModal = productOrderId => {
    setIsOpenDeleteModal(true)
    setProductOrderId(productOrderId)
  }

  const handleCreateBackup = productOrderId => {
    dispatch(createProductOrderBackup({ productOrderId }))
    setIsOpenChangeModal(true)
    setProductIdForChange(productOrderId)
  }

  const closeChangeModal = useCallback(() => {
    setIsOpenDeleteModal(false)
    setProductOrderId(null)
  }, [])

  const { country: countryCode } = parsePhoneNumber(studentDetails.parentPhoneNumber)

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('classificationId')
    })
  }, [])

  return (
    <Wrapper>
      <Typography className='title' component='h1'>
        Summary
      </Typography>

      {isSmallScreen && (
        <Accordion
          className='accordion'
          disableGutters
          elevation={0}
          sx={{
            '&.Mui-expanded:first-of-type': {
              marginTop: '16px'
            }
          }}
        >
          <AccordionSummary expandIcon={<SelectAngleIcon />} className='accordion-summary'>
            <ListIcon />

            <Typography className='accordion-title'>Summary</Typography>

            <Typography className='accordion-title accordion-title-price'>
              £{new Intl.NumberFormat('en-IN').format(order.totalOrderAmount)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            <Summary />
          </AccordionDetails>
        </Accordion>
      )}

      {isSmallScreen && <PriceBreakdown />}

      {!isSmallScreen && (
        <Box className='block summary'>
          <Typography className='subtitle' component='h2'>
            Your Programmes
          </Typography>

          {productOrders.map(
            (
              {
                id,
                childProductOrder,
                location,
                product,
                subject,
                age,
                package: productPackage,
                educationalModel,
                programDate,
                transferFrom,
                transferTo
              },
              index
            ) => (
              <Box className='box summary-box' key={id}>
                <Box className='summary-box-section'>
                  <div className='product-location' style={{ background: location?.color || '#9ACAB3' }}>
                    {location.title}
                  </div>

                  <Box className='summary-details-container'>
                    <Box className='product-details'>
                      {product?.featuredImage ? (
                        <img src={product?.featuredImage} alt='Mock alt name' className='product-image' />
                      ) : (
                        <Box className='product-icon'>{subject?.title && <BookIcon />}</Box>
                      )}

                      <Typography className='product-course-name'>{subject?.title.replace(/&amp;/g, '&')}</Typography>

                      <Box className='product-ages'>{age?.title ? age?.title : product?.taxonomy?.age?.title}</Box>
                    </Box>

                    {index > 0 && (
                      <IconButton onClick={() => openRemoveModal(id)} className='product-delete-button'>
                        <TrashIcon />
                      </IconButton>
                    )}

                    <Box className='product-change' onClick={() => handleCreateBackup(id)}>
                      <RefreshIcon />

                      <Typography className='product-change-text'>Change</Typography>
                    </Box>
                  </Box>

                  <Box className='product-general-info'>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography className='product-program-type'>{productPackage?.classification?.title}</Typography>

                      <Box className='product-features'>
                        View Features{' '}
                        <CustomTooltip
                          title={
                            <List
                              header={productPackage?.featuresTitle}
                              options={productPackage?.listOfFeatures.map(item => ({
                                component: (
                                  <Typography key={item} className='list-text'>
                                    <span dangerouslySetInnerHTML={{ __html: item }} />
                                  </Typography>
                                )
                              }))}
                            />
                          }
                        >
                          <IconButton sx={{ padding: 0, marginLeft: '6px' }}>
                            <InfoIcon />
                          </IconButton>
                        </CustomTooltip>
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '7.5px' }}>
                      {productPackage?.packageTitle && (
                        <>
                          {' '}
                          <Typography className='product-program'>{productPackage?.packageTitle}</Typography>
                        </>
                      )}
                      {location.title === 'Online' && (
                        <>
                          {' '}
                          <span className='product-divider'></span>
                          <Typography className='product-dates'>{productPackage?.description}</Typography>
                        </>
                      )}
                      {programDate?.title && (
                        <>
                          {' '}
                          <span className='product-divider'></span>
                          <Typography className='product-dates'>{programDate?.title}</Typography>
                        </>
                      )}
                    </Box>
                    {location.title !== 'Online' && (
                      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '7.5px' }}>
                        {((transferFrom !== false && transferFrom !== null) ||
                          (transferTo !== false && transferTo !== null)) && (
                          <>
                            <Typography className='product-program'>
                              Airport transfer (
                              {
                                transferOptionsValues.find(
                                  option => option.transferFrom === transferFrom && option.transferTo === transferTo
                                )?.label
                              }
                              )
                            </Typography>
                            <CustomTooltip
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    '& .title': {
                                      fontSize: '14px',
                                      fontWeight: 450,
                                      lineHeight: '21px',
                                      color: '#1d1d1d'
                                    },
                                    '& .box': {
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      gap: '8px'
                                    },
                                    '& .dot': {
                                      height: '4px',
                                      width: '4px',
                                      borderRadius: '50%',
                                      background: '#1d1d1d'
                                    },
                                    '& .item': {
                                      fontSize: '14px',
                                      fontWeight: 300,
                                      lineHeight: '21px',
                                      color: '#1d1d1d'
                                    }
                                  }
                                }
                              }}
                              title={
                                <Box>
                                  <Typography className='title'>
                                    Airport transfers will be from the following destinations
                                  </Typography>
                                  <Box className='box'>
                                    <Box className='dot' />
                                    <Typography className='item'>London Heathrow Airport</Typography>
                                  </Box>
                                  <Box className='box'>
                                    <Box className='dot' />
                                    <Typography className='item'>Sydney Kingsford Smith Airport</Typography>
                                  </Box>
                                  <Box className='box'>
                                    <Box className='dot' />
                                    <Typography className='item'>Toronto Pearson International Airport</Typography>
                                  </Box>
                                  <Box className='box'>
                                    <Box className='dot' />
                                    <Typography className='item'>John F. Kennedy International Airport</Typography>
                                  </Box>
                                  <Box className='box'>
                                    <Box className='dot' />
                                    <Typography className='item'>San Francisco International Airport </Typography>
                                  </Box>
                                  <Typography className='item'>
                                    Transfer details will be confirmed closer to the flight time.
                                  </Typography>
                                </Box>
                              }
                            >
                              <IconButton sx={{ padding: 0, marginLeft: '6px' }}>
                                <InfoIcon />
                              </IconButton>
                            </CustomTooltip>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>

                {childProductOrder && (
                  <Box className='summary-box-section'>
                    <div
                      className='product-location'
                      style={{ background: childProductOrder?.location?.color || '#181C2A' }}
                    >
                      {childProductOrder.location.title}
                    </div>

                    <Box className='summary-details-container'>
                      <Box className='product-details'>
                        <img
                          src={childProductOrder?.product?.featuredImage}
                          alt='Mock alt name'
                          className='product-image'
                        />

                        <Typography className='product-course-name'>
                          {childProductOrder.subject?.title.replace(/&amp;/g, '&')}
                        </Typography>

                        <Box className='product-ages'>{childProductOrder.age?.title}</Box>
                      </Box>
                    </Box>

                    <Box className='product-general-info'>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography className='product-program-type'>
                          {childProductOrder?.package?.classification?.title}
                        </Typography>

                        <Box className='product-features'>
                          View Features{' '}
                          <CustomTooltip
                            title={
                              <List
                                header={childProductOrder?.package?.featuresTitle}
                                options={childProductOrder?.package?.listOfFeatures.map(item => ({
                                  component: (
                                    <Typography key={item} className='list-text'>
                                      <span dangerouslySetInnerHTML={{ __html: item }} />
                                    </Typography>
                                  )
                                }))}
                              />
                            }
                          >
                            <IconButton sx={{ padding: 0, marginLeft: '6px' }}>
                              <InfoIcon />
                            </IconButton>
                          </CustomTooltip>
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '7.5px' }}>
                        <Typography className='product-program'>{childProductOrder?.package?.packageTitle}</Typography>

                        <span className='product-divider'></span>

                        {product?.dateDuration && (
                          <>
                            <Typography className='product-dates'>{product?.dateDuration}</Typography>

                            <span className='product-divider'></span>
                          </>
                        )}

                        <Typography className='product-dates'>
                          {childProductOrder?.preferredMonths?.map(item => item.month).join(', ')} (bespoke dates)
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
      )}

      <Box className='block'>
        <Typography className='subtitle' component='h2'>
          Student’s Details
        </Typography>

        <Box className='details box'>
          <Box className='details-section'>
            <Box className='details-section-header'>
              <Typography className='header'>Student’s Personal Details</Typography>

              <Typography className='edit' onClick={() => navigate('/participant-details?navigate=summary')}>
                Edit
              </Typography>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>First name:</Box>

              <Box className='col-right'>{studentDetails.firstName}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Last name:</Box>

              <Box className='col-right'>{studentDetails.lastName}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Email address:</Box>

              <Box className='col-right'>{studentDetails.email}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Date of birth:</Box>

              <Box className='col-right'>{format(new Date(studentDetails.dateOfBirth), 'dd/MM/Y')}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Pronouns:</Box>

              <Box className='col-right'>{studentDetails.gender}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Nationality:</Box>

              <Box className='col-right'>{studentDetails.nationality}</Box>
            </Box>
          </Box>

          <Box className='details-section'>
            <Box className='details-section-header'>
              <Typography className='header'>Student’s Academic Details</Typography>

              <Typography className='edit' onClick={() => navigate('/academic-details?navigate=summary')}>
                Edit
              </Typography>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Country of residence:</Box>

              <Box className='col-right empty'>{studentDetails.country}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Name of current school/college:</Box>

              <Box className='col-right empty'>{studentDetails.schoolName}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>School website:</Box>

              <Box className='col-right empty'>{studentDetails.schoolWebsiteLink || '-'}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Consultant role:</Box>

              <Box className='col-right empty'>{studentDetails.roleOfConsultant || '-'}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Full name:</Box>

              <Box className='col-right empty'>{studentDetails.nameOfConsultantOrAdvisor || '-'}</Box>
            </Box>
          </Box>

          <Box className='details-section'>
            <Box className='details-section-header'>
              <Typography className='header'>Parent/Guardian Details</Typography>

              <Typography className='edit' onClick={() => navigate('/parent-guardian-details?navigate=summary')}>
                Edit
              </Typography>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>First name:</Box>

              <Box className='col-right empty'>{studentDetails.parentFirstName}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Last name:</Box>

              <Box className='col-right empty'>{studentDetails.parentLastName}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Email address:</Box>

              <Box className='col-right empty'>{studentDetails.parentEmail}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Home phone number</Box>

              <Box className='col-right phone'>
                <img
                  loading='lazy'
                  width='24'
                  height='18'
                  srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
                  alt={`${countryCode} flag`}
                />
                {studentDetails.parentPhoneNumber}
              </Box>
            </Box>
          </Box>

          <Box className='details-section'>
            <Box className='details-section-header'>
              <Typography className='header'>Sibling Details</Typography>

              <Typography className='edit' onClick={() => navigate('/parent-guardian-details?navigate=summary')}>
                Edit
              </Typography>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>First name:</Box>

              <Box className='col-right empty'>{studentDetails.siblingFirstName || '-'}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Last name:</Box>

              <Box className='col-right empty'>{studentDetails.siblingLastName || '-'}</Box>
            </Box>

            <Box className='details-section-row'>
              <Box className='col-left'>Date of birth:</Box>

              <Box className='col-right empty'>
                {studentDetails.siblingDateOfBirth
                  ? format(new Date(studentDetails.siblingDateOfBirth), 'dd/MM/Y')
                  : '-'}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className='following-section'>
        <Typography className='following-title'>
          You are about to secure your enrollment to Immerse. After your enrollment we will contact you with the
          following:
        </Typography>
        <Box className='following'>
          <EmailIcon />

          <Typography className='following-text'>Enrolment confirmation email</Typography>
        </Box>

        <Box className='following'>
          <InfoStrokeIcon />

          <Typography className='following-text'>
            Detailed information to help you prepare for your chosen programme
          </Typography>
        </Box>
      </Box>

      <Box className='buttons-block'>
        <CustomButton isLight onClick={() => navigate(-1)}>
          Back
        </CustomButton>

        <CustomButton type='submit' sx={{ padding: '14px 28px 14px 32px' }} onClick={() => navigate('/payment')}>
          Continue to Payment <ArrowRight />
        </CustomButton>
      </Box>

      <RemoveModal open={isOpenDeleteModal} onClose={closeChangeModal} onDelete={() => handleDeleteProductOrder()} />

      {productIdForChange && (
        <ChangeProgrammeModal
          open={isOpenChangeModal}
          onClose={() => {
            setIsOpenChangeModal(false)
            setProductIdForChange(null)
          }}
          productOrderId={productIdForChange}
        />
      )}
    </Wrapper>
  )
}

export default SummaryScreen
