import { Box } from '@mui/material'

import styled from 'styled-components'

export const StyledPhoneInput = styled(Box)`
  display: flex;
  gap: 2px;
  align-items: center;
  height: 48px;
  width: 124px;
  min-width: 124px;
  padding: 0 12px;
  border-radius: 8px;
  border: ${({ error }) => {
    return `1px solid ${error ? '#C8102E' : '#d5dadf'}`
  }};
  background: #fff;

  img {
    margin-right: 2px;
  }

  .MuiAutocomplete-popper {
    width: 136px !important;
    min-width: 136px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiAutocomplete-root {
    .MuiFormControl-root .MuiOutlinedInput-root {
      padding-right: 0 !important;
    }
    .MuiInputBase-input {
      font-weight: 500;
      padding: 12px 0px;
    }

    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }
  }

  .MuiPopper-root {
    width: 136px !important;
    min-width: 136px !important;
  }
`
