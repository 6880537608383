import { ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'

import CheckIcon from '../../assets/icons/CheckIcon'
import { StyledList } from './styled'

const List = ({ header, options }) => {
  return (
    <StyledList
      subheader={
        <ListSubheader disableSticky component='h4' id='list-subheader' className='list-subheader'>
          {header}
        </ListSubheader>
      }
    >
      {options.map(option => (
        <ListItem className='list-item'>
          <ListItemIcon className='list-item-icon'>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText className='list-text'>{option.component}</ListItemText>
        </ListItem>
      ))}
    </StyledList>
  )
}

export default List
