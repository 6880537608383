import { Autocomplete } from '@mui/material'

import styled from 'styled-components'

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      padding: 0;
      padding-right: 12px !important;
    }

    .MuiSvgIcon-root {
      transition: transform 0.1s ease-in-out;
    }

    input[aria-expanded='true'] {
      ~ .MuiSvgIcon-root {
        transform: rotate(-180deg);
      }
    }

    input[aria-expanded='true'] {
      ~ .search-icon {
        transform: rotate(0deg);
      }
    }

    .search-icon {
      path {
        fill: #c9c9c9;
      }
    }

    .Mui-focused {
      path {
        fill: #181c2a;
      }
    }
  }
`
