import AcademicDetails from '../pages/AcademicDetails'
import AddAnotherProgramme from '../pages/AddAnotherProgramme'
import ParentGuardianDetails from '../pages/ParentGuardianDetails'
import ParticipantDetails from '../pages/ParticipantDetails'
import Payment from '../pages/Payment'
import StartEducationalPathway from '../pages/StartEducationalPathway'
import Success from '../pages/Success'
import Summary from '../pages/Summary'

export const paths = {
  startEducationalPathway: '/start-educational-pathway',
  addAnotherProgramme: '/add-another-programme',
  participantDetails: '/participant-details',
  parentGuardianDetails: '/parent-guardian-details',
  educationModel: '/education-model',
  location: '/location',
  course: '/course',
  academicDetails: '/academic-details',
  summary: '/summary',
  payment: '/payment',
  success: '/success'
}

export const routes = [
  {
    path: paths.startEducationalPathway,
    Component: <StartEducationalPathway />
  },
  {
    path: paths.addAnotherProgramme,
    Component: <AddAnotherProgramme />
  },
  {
    path: paths.participantDetails,
    Component: <ParticipantDetails />
  },
  {
    path: paths.academicDetails,
    Component: <AcademicDetails />
  },
  {
    path: paths.summary,
    Component: <Summary />
  },
  {
    path: paths.parentGuardianDetails,
    Component: <ParentGuardianDetails />
  },
  {
    path: paths.success,
    Component: <Success />
  },
  {
    path: paths.payment,
    Component: <Payment />
  }
]
