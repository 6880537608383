import cn from 'classnames'
import React, { useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useMediaQuery } from '@mui/material'

import ArrowRight from '../../assets/icons/ArrowRight'
import ListIcon from '../../assets/icons/ListIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import Autocomplete from '../../components/Autocomplete'
import CustomButton from '../../components/Button'
import Form from '../../components/Form'
import Select from '../../components/Select'
import Summary from '../../components/Summary'
import Input from '../../components/TextField'
import { DAYS, MONTHS, YEARS, countries } from '../../constants/participantDetailsData'
import { emailRegex } from '../../helpers/validate'
import { addPersonalDetails } from '../../store/enrolment/enrolment.actions'
import { selectMainMainOrderId, selectOrder, selectStudentDetails } from '../../store/enrolment/enrolment.selectors'
import { formatNumberWithLeadingZero, monthMap, monthsNames } from '../../utils/dateToNumber'
import { Wrapper } from './styled'

import { useDispatch, useSelector } from 'react-redux'

const ParticipantDetailsScreen = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const dispatch = useDispatch()

  const currentStudentDetails = useSelector(selectStudentDetails)
  const orderId = useSelector(selectMainMainOrderId)
  const order = useSelector(selectOrder)
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const [isLoading, setIsLoading] = useState(false)

  const initialState = useMemo(
    () => ({
      ...currentStudentDetails,
      dayOfBirth: currentStudentDetails.dateOfBirth
        ? formatNumberWithLeadingZero(new Date(currentStudentDetails.dateOfBirth).getDate())
        : '',
      monthOfBirth: monthsNames[new Date(currentStudentDetails.dateOfBirth).getMonth()] || '',
      yearOfBirth: new Date(currentStudentDetails.dateOfBirth).getFullYear() || ''
    }),
    [currentStudentDetails]
  )

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    formState: { errors }
  } = useForm({ mode: 'onChange', defaultValues: initialState })

  const { field: firstName } = useController({
    name: 'firstName',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      minLength: { value: 2, message: 'Enter a valid name' }
    }
  })

  const { field: lastName } = useController({
    name: 'lastName',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      minLength: { value: 2, message: 'Enter a valid name' }
    }
  })

  const { field: email } = useController({
    name: 'email',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      pattern: {
        value: emailRegex,
        message: 'Invalid email address'
      }
    }
  })

  const { field: day } = useController({
    name: 'dayOfBirth',
    defaultValue: null,
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: month } = useController({
    name: 'monthOfBirth',
    defaultValue: null,
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: year } = useController({
    defaultValue: null,
    name: 'yearOfBirth',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: gender } = useController({
    defaultValue: null,
    name: 'gender',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: nationality } = useController({
    defaultValue: null,
    name: 'nationality',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const selectCountry = selectedCountry => {
    const countryForChange = selectedCountry?.label || selectedCountry
    nationality.onChange(countryForChange)
  }

  const onSubmit = data => {
    setIsLoading(true)
    const { yearOfBirth, monthOfBirth, dayOfBirth, email, ...other } = data
    const month = monthMap[monthOfBirth]

    const details = {
      ...other,
      email: email.trim(),
      dateOfBirth: `${yearOfBirth}-${month}-${dayOfBirth}`
    }
    const paramsUrl = params.get('navigate')
    const navigateUrl = paramsUrl ? `/${paramsUrl}` : `/academic-details`

    if (isDirty) {
      dispatch(
        addPersonalDetails({
          orderId,
          body: details,
          callback: () => {
            setIsLoading(false)

            navigate(navigateUrl)
          },
          callbackRejected: errMessage => {
            setIsLoading(false)
            toast.error(errMessage)
          }
        })
      )
    } else {
      navigate(navigateUrl)
    }
  }

  const dateOfBirthError = errors?.dayOfBirth?.message || errors?.monthOfBirth?.message || errors?.yearOfBirth?.message

  return (
    <Wrapper>
      {isSmallScreen && (
        <Accordion
          className='accordion'
          disableGutters
          elevation={0}
          sx={{
            '&.Mui-expanded:first-of-type': {
              marginTop: '16px'
            }
          }}
        >
          <AccordionSummary expandIcon={<SelectAngleIcon />} className='accordion-summary'>
            <ListIcon />

            <Typography className='accordion-title'>Summary</Typography>

            <Typography className='accordion-title accordion-title-price'>
              £{new Intl.NumberFormat('en-IN').format(order.totalOrderAmount)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            <Summary />
          </AccordionDetails>
        </Accordion>
      )}

      <Typography className='title' component='h1'>
        Student’s Personal Details
      </Typography>

      <Typography className='text'>
        Complete the details for the student who will be taking the Immerse course
      </Typography>

      <Form
        className='form'
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <>
            <CustomButton isLight onClick={() => navigate(-1)}>
              Back
            </CustomButton>

            <CustomButton type='submit' disabled={isLoading}>
              Next <ArrowRight />
            </CustomButton>
          </>
        }
      >
        <Box className='form-col'>
          <Box className='form-grid-col'>
            <Input
              value={firstName.value}
              onChange={firstName.onChange}
              error={errors?.firstName?.message}
              containerClass='form-item'
              label='Student first name'
              placeholder='Enter first name'
              name='studentFirstName'
            />

            <Input
              value={lastName.value}
              onChange={lastName.onChange}
              error={errors?.lastName?.message}
              containerClass='form-item'
              label='Student last name'
              placeholder='Enter last name'
              name='studentLastName'
            />
          </Box>

          <Input
            value={email.value}
            onChange={email.onChange}
            error={errors?.email?.message}
            containerClass='form-item'
            label='Student email address'
            placeholder='Enter email address'
            name='studentEmail'
            helperText="Please provide the student's personal email as this will be used to create their profile on our learner platform."
          />

          <Box className='form-item'>
            <Typography
              className={cn('text-field-label', {
                'text-field-label-error': dateOfBirthError
              })}
            >
              Student date of birth
            </Typography>

            <Box className='form-col-3'>
              <Select
                onChange={day.onChange}
                error={!!errors?.dayOfBirth?.message}
                options={DAYS}
                value={day.value}
                placeholder='DD'
              />

              <Select
                onChange={month.onChange}
                error={!!errors?.monthOfBirth?.message}
                options={MONTHS}
                value={month.value}
                placeholder='MM'
              />

              <Select
                onChange={year.onChange}
                error={!!errors?.yearOfBirth?.message}
                options={YEARS}
                value={year.value}
                placeholder='YYYY'
              />
            </Box>

            {dateOfBirthError && (
              <Typography
                className='text-field-label text-field-label-message-error'
                sx={{ marginBottom: '0 !important' }}
              >
                This field is required
              </Typography>
            )}
          </Box>

          <Select
            onChange={gender.onChange}
            error={errors?.gender?.message}
            className='form-item'
            options={['He/Him/His', 'She/Her/Hers', 'They/Them/Theirs']}
            value={gender.value}
            placeholder='Pronouns'
          />

          <Autocomplete
            className='form-item'
            options={countries}
            error={errors?.nationality?.message}
            placeholder='Nationality'
            onSelect={selectCountry}
            initialValue={nationality.value}
            initialInputValue={nationality.value}
            renderOption={(props, option) => (
              <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading='lazy'
                  width='20'
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt=''
                />
                {option.label}
              </Box>
            )}
          />
        </Box>
      </Form>
    </Wrapper>
  )
}

export default ParticipantDetailsScreen
