import { SvgIcon } from '@mui/material'

const CheckmarkIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 24, height: 24, fill: 'none', ...sx }} {...props} viewBox='0 0 24 24'>
      <path d='M6 12L10 16L18 8' stroke='#BB945C' strokeWidth='3' />
    </SvgIcon>
  )
}

export default CheckmarkIcon
