import { createSlice } from '@reduxjs/toolkit'

import {
  addAcademicDetails,
  addBillingAddress,
  addCoupon,
  addMailConsent,
  addPackage,
  addParentGuardianDetails,
  addPersonalDetails,
  addProgramDate,
  addTransferDetails,
  createNewProductOrder,
  createOrder,
  deleteProductOrder,
  discardProductOrder,
  getAgesByOrder,
  getEducationalModelsByProductOrder,
  getLocationsByOrder,
  getOrder,
  getProgramDatesByProductOrder,
  getSubjectsByOrder,
  removeCoupon,
  saveProductOrder,
  selectAge,
  selectChildSubject,
  selectEducationalModel,
  selectLocation,
  selectPreferredChildMonths,
  selectPreferredMonths,
  selectSubject,
  validateOrder
} from './enrolment.actions'

const initialState = {
  order: null,
  locationsByOrder: null,
  agesByOrder: null,
  subjectsByOrder: null,
  educationalModels: null,
  programDates: null,
  activeProductOrderId: null,
  startPathwayScreen: null,
  isNewProgramAdded: null,
  isDeposit: false,
  isMainProductSaved: true,
  isOpenChangeModal: false
}

export const detailsSlice = createSlice({
  name: 'enrolment',
  initialState,
  reducers: {
    setActiveProductOrderId(state, { payload }) {
      state.activeProductOrderId = payload
    },
    setStartPathwayScreen(state, { payload }) {
      state.startPathwayScreen = payload
    },
    setisNewProgramAdded(state, { payload }) {
      state.isNewProgramAdded = payload
    },
    setIsDeposit(state, { payload }) {
      state.isDeposit = payload
    },
    setIsMainProductSaved(state, { payload }) {
      state.isMainProductSaved = payload
    },
    setIsChangeModalOpen(state, { payload }) {
      state.isOpenChangeModal = payload
    }
  },
  extraReducers: builder => {
    builder.addCase(createOrder.fulfilled, (state, { payload }) => {
      state.order = payload

      localStorage.setItem('orderId', payload.id)
    })

    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(getLocationsByOrder.fulfilled, (state, { payload }) => {
      state.locationsByOrder = payload
    })

    builder.addCase(createNewProductOrder.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(discardProductOrder.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(deleteProductOrder.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(selectLocation.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(getAgesByOrder.fulfilled, (state, { payload }) => {
      state.agesByOrder = payload
    })

    builder.addCase(getProgramDatesByProductOrder.fulfilled, (state, { payload }) => {
      state.programDates = payload
    })

    builder.addCase(selectAge.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(getSubjectsByOrder.fulfilled, (state, { payload }) => {
      state.subjectsByOrder = payload
    })

    builder.addCase(selectSubject.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(selectChildSubject.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(selectPreferredMonths.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(selectPreferredChildMonths.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(getEducationalModelsByProductOrder.fulfilled, (state, { payload }) => {
      state.educationalModels = payload
    })

    builder.addCase(selectEducationalModel.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(addPackage.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(addProgramDate.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(addTransferDetails.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(saveProductOrder.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(addPersonalDetails.fulfilled, (state, { payload }) => {
      state.order.studentDetails = payload
    })

    builder.addCase(addAcademicDetails.fulfilled, (state, { payload }) => {
      state.order.studentDetails = payload
    })

    builder.addCase(addParentGuardianDetails.fulfilled, (state, { payload }) => {
      state.order.studentDetails = payload
    })

    builder.addCase(addCoupon.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(removeCoupon.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(addBillingAddress.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(addMailConsent.fulfilled, (state, { payload }) => {
      state.order = payload
    })

    builder.addCase(validateOrder.fulfilled, (state, { payload }) => {
      state.order = payload
    })
  }
})

export const {
  setActiveProductOrderId,
  setStartPathwayScreen,
  setisNewProgramAdded,
  setIsDeposit,
  setIsMainProductSaved,
  setIsChangeModalOpen
} = detailsSlice.actions

export default detailsSlice.reducer
