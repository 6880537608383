import { Box } from '@mui/system'

import styled from 'styled-components'

export const StyledHeader = styled(Box)`
  width: 100%;
  height: 8.8rem;

  .header-container {
    position: fixed;
    display: flex;
    z-index: 100;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 34.8rem);
    height: inherit;

    padding: 3.2rem 3.2rem 3.2rem 6.4rem;
    background-color: #fff;

    @media (max-width: 1376px) {
      width: calc(100% - 30.8rem);
    }

    @media (max-width: 1182px) {
      width: calc(100% - 26.8rem);
    }

    .header-consult {
      display: flex;
      align-items: center;
      gap: 3.2rem;
      border-radius: 1.6rem;
      transition: background-color 0.2s ease-in-out;

      &-text {
        color: #1d1d1d;
        font-weight: 600;
        font-size: 1.6rem;
      }

      &-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 1.6rem;

        svg {
          margin-right: 8px;
        }
      }
    }

    .header-guarantee {
      display: flex;
      align-items: center;
      /* padding: 0.8rem 1.6rem; */
      border-radius: 1.6rem;
      transition: background-color 0.2s ease-in-out;

      /* &:hover {
        background-color: #f1eee9;
      } */

      &-text {
        margin-left: 8px;
        ${({ theme }) => theme.typography.textMain};
      }

      svg {
      }
    }
  }
`
