import { List } from '@mui/material'

import styled from 'styled-components'

export const StyledList = styled(List)`
  .list-subheader {
    font-weight: 450;
    font-size: 14px;
    line-height: 21px;
    color: #1d1d1d;
    padding-left: 0;
  }

  .list-item {
    padding: 0;
    align-items: flex-start;
  }

  .list-item-icon {
    min-width: 0;
    margin-top: 10px;
    margin-right: 9px;
  }
  .list-text {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #1d1d1d;

    & b {
      font-weight: 450;
    }
  }
`
