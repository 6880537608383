import { Box } from '@mui/material'

import styled from 'styled-components'

export const StyledDiscountBlock = styled(Box)``

export const StyledDiscountForm = styled.form`
  position: relative;
  padding: 4px 3px 3px 16px;
  border: 1px solid #c0c8cf;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  background: white;

  input {
    padding: 0;
    width: 80%;
    border: none;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 450;
    line-height: 24px;

    &:focus {
      outline: none;
    }
  }

  button[type='submit'] {
    background: #1d1d1d;
    border: none;
    border-radius: 4px;
    width: 63px !important;
    height: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: 'Circular';
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #2d4960;
    }
  }

  &.discounted {
    background: #f5f5f7;

    input {
      color: #c9c9c9;
      background: #f5f5f7;
      pointer-events: none;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      background: #b8945c;

      svg {
        width: 20px;
        height: 20px;
        path {
          fill: white;
        }
      }
    }
  }

  &.in-accordion {
    height: 49px;
    justify-content: space-between;

    button {
      margin-bottom: 0 !important;
      height: 100% !important;
    }
  }

  .approve {
    position: absolute;
    z-index: 10;
    top: calc(100% + 16px);
    left: 0;
    right: 0;

    display: flex;

    gap: 8px;

    border-radius: 8px;
    border: 1px solid #bb945c;
    background-color: #fff;

    padding: 16px 16px 16px 12px;

    p {
      color: #1d1d1d;

      font-size: 14px;

      font-weight: 300;
      line-height: 21px;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 16px;

      button {
        font-family: 'Circular';
        font-size: 14px;
        font-weight: 450;
        line-height: 140%;
        padding: 6px 12px;
        border-radius: 6px;
        border: none;
        cursor: pointer;

        transition: background-color 0.15s ease-in-out;
      }

      .apply {
        background-color: #181c2a;
        color: #fff;

        &:hover {
          background-color: #2d4960;
        }
      }

      .delete {
        background-color: #fff;
        color: #2d4960;

        &:hover {
          background-color: #f5f5f7;
        }
      }
    }
  }

  .drawer {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #f5f5f7;
    opacity: 0.6;

    border-radius: 8px;
  }
`

export const StyledDiscountError = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 12px 0;

  span {
    color: #c8102e;
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
  }

  button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    border: none;
    background: transparent;
    color: #2d4960;
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;
    cursor: pointer;
  }
`

export const StyledDiscountTitle = styled.h6`
  color: #2d4960;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  padding: 12px 0;
  border-bottom: 1px solid #dadada;
  letter-spacing: -0.1px;
  cursor: pointer;

  svg {
    margin-left: 8px;
  }
`
export const StyledCoupons = styled(Box)`
  padding-top: 16px;
  display: flex;

  .coupon {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 4px 8px 16px;

    background-color: #fff;

    border-radius: 8px;

    color: #1d1d1d;

    font-size: 16px;
    font-weight: 450;
    line-height: 24px;

    svg {
      margin-left: 16px;
      cursor: pointer;
    }

    .drawer {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #f5f5f7;
      opacity: 0.6;

      border-radius: 8px;
    }
  }

  .coupon-gray {
    background-color: #f5f5f7;
  }
`

export const StyledAlert = styled(Box)`
  border-radius: 8px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #18ab56;
  padding-left: 3px;
  margin-top: 16px;

  div {
    display: flex;
    align-items: center;
    padding: 8px 14px;
    border-radius: 8px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: #daf4e5;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    svg {
      margin-right: 10px;
    }
  }
`
