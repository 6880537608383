export const monthsData = [
  { label: 'Jan', selected: false, value: 'Jan', number: 0 },
  { label: 'Feb', selected: false, value: 'Feb', number: 1 },
  { label: 'Mar', selected: false, value: 'Mar', number: 2 },
  { label: 'Apr', selected: false, value: 'Apr', number: 3 },
  { label: 'May', selected: false, value: 'May', number: 4 },
  { label: 'Jun', selected: false, value: 'Jun', number: 5 },
  { label: 'Jul', selected: false, value: 'Jul', number: 6 },
  { label: 'Aug', selected: false, value: 'Aug', number: 7 },
  { label: 'Sep', selected: false, value: 'Sep', number: 8 },
  { label: 'Oct', selected: false, value: 'Oct', number: 9 },
  { label: 'Nov', selected: false, value: 'Nov', number: 10 },
  { label: 'Dec', selected: false, value: 'Dec', number: 11 }
]
