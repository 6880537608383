import { SvgIcon } from '@mui/material'

const EmailIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', width: 24, height: 24, ...sx }}
      {...props}
      viewBox='0 0 24 24'>
      <g clipPath='url(#clip0_217_5574)'>
        <path
          d='M4.29999 7.90506V6.26582C4.29999 5.56751 4.90591 5 5.65148 5H20.6485C21.3941 5 22 5.56751 22 6.26582V17.7342C22 18.4325 21.3941 19 20.6485 19H5.65148C4.90591 19 4.29999 18.4325 4.29999 17.7342V16.3586M0 10.5464H4.29999M4.29989 13.5844H1.83342M8.24867 8.76372L13.1501 12L17.8397 8.76372'
          stroke='#1D1D1D'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_217_5574'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default EmailIcon
