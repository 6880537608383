import Toast from '../../components/Toast'
import ParticipantDetailsScreen from '../../screens/ParticipantDetailsScreen'

const ParticipantDetails = () => {
  return (
    <>
      <Toast />
      <ParticipantDetailsScreen />
    </>
  )
}

export default ParticipantDetails
