import { axiosClassic } from '../api/api'

export const SchoolService = {
  async getSchoolsByCountry(country) {
    try {
      const res = await axiosClassic(`/school/country/${country}`)

      return res.data
    } catch (error) {
      return []
    }
  },

  async postSchool(body) {
    const { data: school } = await axiosClassic.post(`/school`, body)

    return school
  },

  async getSchool(schoolId) {
    try {
      const { data: school } = await axiosClassic.get(`/school/${schoolId}`)

      return school
    } catch (error) {}
  }
}
