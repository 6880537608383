import { Box } from '@mui/material'

import styled from 'styled-components'

export const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 32px;

  @media (max-width: 1024px) {
    padding-right: 0;
  }

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #dadada;

    border: 4px solid #dadada;

    border-radius: 99999px;
  }

  .title {
    ${({ theme }) => theme.typography.h1};
    font-weight: 600;

    @media (max-width: 1024px) {
      text-align: center;
      padding-top: 16px;
      font-size: 22px;
      font-weight: 500;
      line-height: 28.6px;
    }
  }

  .subtitle {
    ${({ theme }) => theme.typography.h3};
    line-height: 32px;
    font-weight: 600;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  .text {
    margin-top: 0.8rem;
    ${({ theme }) => theme.typography.textMain};
    line-height: 2.2rem;
    font-weight: 300;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  .text-field-label {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.typography.textSecondary};
    white-space: initial;
    margin-bottom: 0.8rem;

    &-error {
      color: #c8102e;
    }

    &-message-error {
      color: #c8102e;
      font-size: 12px;
      line-height: 18px;
      margin-top: 4px;
    }
  }

  .form {
    &-col {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      width: 100%;

      @media (max-width: 1200px) {
        width: 55.8rem;
      }
      @media (max-width: 1024px) {
        width: 100%;
      }

      &-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
      }
    }

    &-item {
      flex-basis: calc(50% - 0.8rem);

      @media (max-width: 768px) {
        flex-basis: 100%;
      }

      &-full {
        flex-basis: 100%;
      }
    }

    .phone-input {
      width: 100%;
    }
  }

  .accordion {
    margin-top: 16px;
    border: 1px solid #d5dadf;
    border-radius: 16px;
    padding: 0;

    & .MuiCollapse-root {
      border-top: 1px solid #d5dadf;
    }
  }

  .accordion-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;

    &.Mui-expanded {
      height: 48px;
      min-height: 48px;
    }
  }

  .accordion-title {
    padding-left: 12px;
    color: #2d4960;
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;

    &-price {
      color: #1d1d1d;
    }
  }

  .accordion-details {
    padding: 0;
  }

  .bordered-discount {
    border: 1px solid #d5dadf;
    border-radius: 8px;
    padding: 12px 16px;
  }
`
