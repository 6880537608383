import { SvgIcon } from '@mui/material'

const CheckIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 11, height: 8, ...sx }} {...props} viewBox='0 0 11 8'>
      <path d='M3.5 6.32836L0.875 3.8209L0 4.65672L3.5 8L11 0.835821L10.125 0L3.5 6.32836Z' fill='#18AB56' />
    </SvgIcon>
  )
}

export default CheckIcon
