import cn from 'classnames'

import { Box } from '@mui/material'

import { StyledForm } from './styled'

const Form = ({ onSubmit, footer, isGap, isButtonSticky = true, children, ...props }) => {
  return (
    <StyledForm onSubmit={onSubmit} {...props}>
      <Box
        className={cn('form-container', {
          isGap: isGap
        })}
      >
        {children}
      </Box>
      <Box
        className={cn('form-footer', {
          isButtonSticky: isButtonSticky
        })}
      >
        {footer}
      </Box>
    </StyledForm>
  )
}

export default Form
