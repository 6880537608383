import cn from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { Button } from '@mui/base'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
  useMediaQuery
} from '@mui/material'

import { errorCatch } from '../../api/helpers'
import PriceBreakdownIcon from '../../assets/icons/PriceBreakdownIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import Autocomplete from '../../components/Autocomplete'
import CustomButton from '../../components/Button'
import Form from '../../components/Form'
import PriceBreakdown from '../../components/PriceBreakdown'
import Input from '../../components/TextField'
import { countries } from '../../constants/participantDetailsData'
import { emailRegex } from '../../helpers/validate'
import { OrderService } from '../../services/order'
import { addBillingAddress, addMailConsent } from '../../store/enrolment/enrolment.actions'
import { removeCoupon } from '../../store/enrolment/enrolment.actions'
import { selectBillingAddress, selectMainMainOrderId, selectOrder } from '../../store/enrolment/enrolment.selectors'
import { WrapperForm } from './styled'

import { useDispatch, useSelector } from 'react-redux'

const PaymentScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const billingAddress = useSelector(selectBillingAddress)
  const order = useSelector(selectOrder)

  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  const initialState = useMemo(
    () => ({
      ...billingAddress
    }),
    [billingAddress]
  )

  const orderId = useSelector(selectMainMainOrderId)
  const [isLoading, setIsLoading] = useState(false)
  const [checkboxValue, setCheckboxValue] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({ mode: 'onChange', defaultValues: initialState })

  const { field: country } = useController({
    name: 'country',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: firstName } = useController({
    name: 'firstName',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: lastName } = useController({
    name: 'lastName',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: firstLineAddress } = useController({
    name: 'firstLineAddress',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: secondLineAddress } = useController({
    name: 'secondLineAddress',
    control: control
  })

  const { field: city } = useController({
    name: 'city',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: county } = useController({
    name: 'province',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: postCode } = useController({
    name: 'postcode',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' }
    }
  })

  const { field: email } = useController({
    name: 'email',
    control: control,
    rules: {
      required: { value: true, message: 'This field is required' },
      pattern: {
        value: emailRegex,
        message: 'Invalid email address'
      }
    }
  })

  const handleReceiveEmails = event => {
    setCheckboxValue(prev => !prev)
    dispatch(
      addMailConsent({
        orderId,
        body: JSON.stringify({
          receiveUpdates: event.target.checked
        })
      })
    )
  }

  const onSubmit = data => {
    const { email, ...other } = data

    setIsLoading(true)
    dispatch(
      addBillingAddress({
        orderId,
        body: JSON.stringify({ email: email.trim(), ...other }),
        callback: data => {
          if (data?.paymentType === 'stripe') {
            window.location.href = data?.url
          } else if (data?.paymentType === 'free') {
            navigate(`/success?orderId=${orderId}`)
          } else if (data?.paymentType === 'flywire') {
            const button = document.getElementById('checkout-button')

            let config = {
              env: `${process.env.REACT_APP_FLYWIRE_MODE}`,
              locale: 'en-EN',
              displayPayerInformation: true,
              provider: 'embed2.0',
              token: data?.token,
              return_url:
                process.env.REACT_APP_FLYWIRE_MODE === 'demo'
                  ? `https://immerse-v2-front-end.vercel.app/success?orderId=${orderId}&paymentType=flywire`
                  : `https://enrol.immerse.education/success?orderId=${orderId}&paymentType=flywire`
            }

            window.flywire.Checkout.render(config, '#checkout-button')

            setTimeout(() => {
              button.click()
            }, 100)
          }
          setIsLoading(false)
        },
        callbackRejected: errMessage => {
          toast.error(errMessage)
          setIsLoading(false)
        }
      })
    )
  }

  useEffect(() => {
    const validate = async () => {
      try {
        await OrderService.validate(orderId)
      } catch (error) {
        toast.error(errorCatch(error))
        const message = error?.response?.data?.message

        if (message === 'Coupon usage limit per person reached' || message === 'Coupon usage limit reached') {
          dispatch(
            removeCoupon({
              orderId: order.id
            })
          )
        }
      }
    }

    if (orderId) {
      validate()
    }
  }, [orderId])

  return (
    <WrapperForm>
      {isSmallScreen && (
        <Accordion
          className='accordion'
          disableGutters
          elevation={0}
          sx={{
            '&.Mui-expanded:first-of-type': {
              marginTop: '16px'
            }
          }}
        >
          <AccordionSummary expandIcon={<SelectAngleIcon />} className='accordion-summary'>
            <PriceBreakdownIcon />

            <Typography className='accordion-title'>Price Breakdown</Typography>

            <Typography className='accordion-title accordion-title-price'>
              £{new Intl.NumberFormat('en-IN').format(order.totalOrderAmount)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            <PriceBreakdown isAccordion={true} />
          </AccordionDetails>
        </Accordion>
      )}

      <Form
        onSubmit={handleSubmit(onSubmit)}
        footer={
          <>
            <CustomButton isLight onClick={() => navigate(-1)}>
              Back
            </CustomButton>
            <CustomButton disabled={isLoading} type='submit'>
              Submit Enrolment
            </CustomButton>
            <Button hidden id='checkout-button' />
          </>
        }
      >
        <div className='scrolled-area'>
          <Typography component='h3'>Billing address</Typography>

          <Box className='billing-card'>
            <Typography
              className={cn('billing-card-label', {
                'billing-card-label-error': errors?.firstName?.message || !!errors?.lastName?.message
              })}
            >
              Name on card
            </Typography>
            <div className='billing-card-line'>
              <Input
                value={firstName.value}
                onChange={firstName.onChange}
                error={errors?.firstName?.message}
                placeholder='First name'
              />
              <Input
                value={lastName.value}
                onChange={lastName.onChange}
                error={errors?.lastName?.message}
                placeholder='Last name'
              />
            </div>

            <Typography
              className={cn('billing-card-label', {
                'billing-card-label-error':
                  errors?.firstLineAddress?.message ||
                  errors?.city?.message ||
                  errors?.province?.message ||
                  errors?.country?.message ||
                  errors?.postcode?.message
              })}
            >
              Billing address
            </Typography>
            <div className='billing-card-line'>
              <Input
                value={firstLineAddress.value}
                onChange={firstLineAddress.onChange}
                error={errors?.firstLineAddress?.message}
                placeholder='First line address'
              />

              <Input
                value={secondLineAddress.value}
                onChange={secondLineAddress.onChange}
                placeholder='Second line address'
              />
            </div>

            <div className='billing-card-line'>
              <Input
                value={city.value}
                onChange={city.onChange}
                error={errors?.city?.message}
                placeholder='Town/City'
              />

              <Input
                value={county.value}
                onChange={county.onChange}
                error={errors?.province?.message}
                placeholder='County/Province'
              />
            </div>

            <div className='billing-card-line'>
              <Autocomplete
                className='form-item'
                error={errors?.country?.message}
                onSelect={selectedCountry => country.onChange(selectedCountry.label)}
                options={countries}
                placeholder='Country'
                initialValue={country.value}
                initialInputValue={country.value}
                renderOption={(props, option) => (
                  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading='lazy'
                      width='20'
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=''
                    />
                    {option.label}
                  </Box>
                )}
              />

              <Input
                value={postCode.value}
                onChange={postCode.onChange}
                error={errors?.postcode?.message}
                placeholder='Postcode'
              />
            </div>

            <Input
              type='email'
              label='Billing email address'
              placeholder='Enter email'
              value={email.value}
              onChange={email.onChange}
              error={errors?.email?.message}
            />
          </Box>

          <FormControlLabel
            className={cn('checkbox-button', { checked: checkboxValue })}
            control={
              <Checkbox
                sx={{
                  color: '#D5DADF',
                  '&.Mui-checked': {
                    color: '#B8945C'
                  },
                  '& .MuiSvgIcon-root': { fontSize: 28 }
                }}
                checked={checkboxValue}
                onChange={handleReceiveEmails}
              />
            }
            label={
              <Typography className='checkbox-label'>
                We partner with{' '}
                <Link
                  onClick={event => {
                    event.preventDefault()
                    window.open('https://www.uniadmissions.co.uk/', '', 'width=800, height=600')
                  }}
                  sx={{
                    fontWeight: 400,
                    '&:hover': {
                      color: '#9e681a !important'
                    }
                  }}
                >
                  UniAdmissions
                </Link>
                , the world’s first Oxbridge prep school. Would you like to hear more about the support they offer?
              </Typography>
            }
          />
        </div>
      </Form>
    </WrapperForm>
  )
}

export default PaymentScreen
