import { SvgIcon } from '@mui/material'

const InfoStrokeIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', width: 24, height: 24, ...sx }}
      {...props}
      viewBox='0 0 24 24'>
      <path
        d='M12 11.4831V16.5725M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12.4715 8.44081C12.4715 8.70123 12.2604 8.91234 12 8.91234C11.7396 8.91234 11.5284 8.70123 11.5284 8.44081C11.5284 8.18039 11.7396 7.96927 12 7.96927C12.2604 7.96927 12.4715 8.18039 12.4715 8.44081Z'
        stroke='#1D1D1D'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default InfoStrokeIcon
