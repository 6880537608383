import styled from 'styled-components'

export const Wrapper = styled.aside`
  .education-info-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 16px;
    background-color: #f5f5f7;
    gap: 6px;
    margin-top: 16px;
  }

  .education-header-info-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .education-info-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
  }

  .education-info-header {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.48px;
    color: #181c2a;
  }

  .education-edit-button {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #2d4960;
    background-color: transparent;
    border: none;
    min-width: 0;
    padding: 0;

    &:hover {
      background-color: #f5f5f7;
      box-shadow: none;
    }
  }

  .education-info-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 18.2px;
    color: #1d1d1d;
  }

  .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #bb945c;
  }
`
