import { SvgIcon } from '@mui/material'

const RefreshIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', width: '24px', height: '24px', ...sx }}
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <path d='M19 7V11H15' stroke='#2D4960' strokeLinecap='round' strokeLinejoin='round' />

      <path d='M5 17V13H9' stroke='#2D4960' strokeLinecap='round' strokeLinejoin='round' />

      <path
        d='M6.59727 9.99947C6.92002 9.04375 7.46854 8.18927 8.19166 7.51577C8.91478 6.84227 9.78893 6.3717 10.7325 6.14797C11.6762 5.92424 12.6585 5.95464 13.5878 6.23633C14.5172 6.51803 15.3633 7.04184 16.0473 7.75889L19 10.6663M5 13.3337L7.95273 16.2411C8.63666 16.9582 9.48278 17.482 10.4122 17.7637C11.3415 18.0454 12.3238 18.0758 13.2675 17.852C14.2111 17.6283 15.0852 17.1577 15.8083 16.4842C16.5315 15.8107 17.08 14.9563 17.4027 14.0005'
        stroke='#2D4960'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default RefreshIcon
