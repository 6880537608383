import { Box } from '@mui/material'

import styled from 'styled-components'

export const SummaryStyled = styled(Box)`
  min-width: 344px;

  .bordered-discount {
    border: 1px solid #d5dadf;
    border-radius: 8px;
    padding: 12px 16px;
  }

  .summary {
    border-left: 1px solid #dadada;
    padding: 0px 32px;
    padding-bottom: 32px;
    height: calc(100vh - 88px - 100px);

    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #dadada;

      border: 4px solid #dadada;

      border-radius: 99999px;
    }

    @media (max-width: 1024px) {
      border-left: none;
      padding: 0;
      padding-bottom: 0;
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    h3 {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 8px;
    }

    &-products-box {
      display: flex;
      flex-direction: column;

      border-radius: 16px;
      border: 1px solid #f0f0f1;
      background-color: #ffffff;

      &:not(:last-of-type) {
        margin-bottom: 16px;
        @media (max-width: 1024px) {
          margin-bottom: 0;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #f0f0f1;
        }
      }

      @media (max-width: 1024px) {
        border: none;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        border-bottom: 1px solid #f0f0f1;
      }
    }

    &-products-wrapper {
      position: relative;
      padding: 24px 16px 16px 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &-products-divider {
      position: relative;
      width: 100%;
      height: 1px;
      border: 1px solid #f0f0f1;
    }
  }

  .product {
    &-location {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 16px;
      padding: 2px 11px;
      border-radius: 0px 0px 8px 8px;
      background: ${({ color }) => (color ? `#${color}` : '#fdb717')};
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }

    &-child-location {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -24px;
      right: 2px;
      padding: 2px 11px;
      border-radius: 0px 0px 8px 8px;
      background: ${({ color }) => (color ? `#${color}` : '#fdb717')};
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }

    &-details {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-image {
      width: 56px;
      height: 56px;
      border-radius: 12px;
    }

    &-course-name {
      margin-left: 16px;
      color: #1d1d1d;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    &-delete-button {
      margin-left: auto;
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }

    &-components {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 8px;

      &-item {
        display: inline-block;
        padding: 4px 16px;
        border-radius: 100px;
        background: #f5f5f7;

        color: #6f6f6f;
        font-size: 13px;
        font-weight: 450;
        line-height: 16px;
      }
    }

    &-dates {
      font-size: 14px;
      font-style: normal;
      font-weight: 450;
      line-height: 21px;
    }

    &-general-info {
      padding: 12px;
      border-radius: 12px;
      background: #f5f5f7;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
      }
    }

    &-additional-info {
      padding-left: 12px;
      border-left: 1px solid #bb945c;

      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 19.5px;
        color: #6f6f6f;
      }
    }

    &-price-item {
      display: flex;
      justify-content: space-between;

      p {
        color: #1d1d1d;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
      }

      &-total {
        p {
          font-weight: 500;
        }
      }
    }

    &-price-item-discount {
      color: #ec1e32 !important;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
    }

    &-change {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 4px 0;
      cursor: pointer;

      svg {
        transition: transform 1.5s ease-in-out;
      }

      &:hover {
        svg {
          transform: rotate(360deg);
        }
      }

      &-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2d4960;
      }
    }

    &-child-program {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;
      background-color: #ffffff;
    }
  }
`

export const StyledTotalFee = styled(Box)`
  width: 100%;
  margin-top: 8px;
  background: #f5f5f7;
  border-radius: 24px;
  padding: 16px;

  .MuiInputBase-adornedEnd {
    padding-right: 4px;
  }

  .referral {
    font-size: 16px;
    line-height: 24px;
    color: #2d4960;
    padding: 12px 0;
    border-bottom: 1px solid #c9c9c9;
    cursor: pointer;
    transition: color 0.1s ease-in-out;

    &:hover {
      color: #1d1d1d;
    }

    &-button {
      cursor: pointer;
      min-width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: none;
      background: #1d1d1d;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;

      &-success {
        background: #b8945c;
      }
    }

    &-refresh {
      display: flex;
      align-items: center;
      gap: 8px;

      &-wrong {
        font-size: 16px;
        line-height: 24px;
        color: #c8102e;
      }

      &-icon {
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;
        color: #2d4960;
      }
    }
  }

  .total {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid #c9c9c9;

    &-item {
      display: flex;
      justify-content: space-between;

      &-price {
        margin-right: 12px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 450;
        color: #1d1d1d;
      }

      &-subject {
        font-size: 14px;
        line-height: 21px;
        color: #1d1d1d;
      }

      &-discount {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 82%;
      }
    }
  }

  .total-price {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;

    &-price,
    &-total {
      font-size: 18px;
      line-height: 27px;
      font-weight: 500;
      color: #1d1d1d;
    }
  }

  .success {
    .MuiInputBase-input {
      color: #c9c9c9;
    }
  }
`

export const StyledDiscount = styled(Box)`
  margin-top: 16px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d5dadf;

  .discount {
    color: #2d4960;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`
