export const newProgrammeOptions = [
  { label: 'Yes, please', value: true },
  { label: 'No, thanks', value: false }
]

export const paymentOptions = [
  { label: 'Pay total', price: 7495, value: 0 },
  { label: 'Pay deposit', price: 995, value: 1 }
]

export const transferOptionsValues = [
  {
    label: 'Pick-up + Return',
    value: 'Pick-up + Return',
    transferFrom: true,
    transferTo: true,
    mostPopular: true,
    price: 300
  },
  {
    label: 'Pick-up only',
    value: 'Pick-up only',
    transferFrom: true,
    transferTo: false,
    mostPopular: false,
    price: 150
  },
  { label: 'Return only', value: 'Return only', transferFrom: false, transferTo: true, mostPopular: false, price: 150 },
  { label: 'No (maybe later)', value: 'No (maybe later)', transferFrom: false, transferTo: false, mostPopular: false }
]
