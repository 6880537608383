import { SvgIcon } from '@mui/material'

const ArrowRight = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 20, height: 20, ...sx }} {...props} viewBox='0 0 20 20'>
      <path
        d='M4.1665 10H15.8332M15.8332 10L9.99984 4.16669M15.8332 10L9.99984 15.8334'
        stroke='white'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default ArrowRight
