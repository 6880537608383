import { SvgIcon } from '@mui/material'

const ListIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ fill: 'none', width: 20, height: 20, ...sx }} {...props} viewBox='0 0 20 20'>
      <path
        d='M5 11.0006H11M5 7.00059H7M5 15.0006H11M14 19.0006H16.5M15 19.0006H5.8C4.11984 19.0006 3.27976 19.0006 2.63803 18.6736C2.07354 18.386 1.6146 17.9271 1.32698 17.3626C1 16.7208 1 15.8807 1 14.2006V3.75768C1 2.85229 1 2.39959 1.1902 2.13707C1.35611 1.90807 1.61123 1.76002 1.89237 1.72958C2.21467 1.69469 2.60772 1.91929 3.39382 2.36849L3.70618 2.54698C3.99552 2.71232 4.14019 2.79499 4.29383 2.82736C4.42978 2.856 4.57022 2.856 4.70617 2.82736C4.85981 2.79499 5.00448 2.71232 5.29382 2.54698L7.20618 1.45421C7.49552 1.28887 7.64019 1.2062 7.79383 1.17383C7.92978 1.14518 8.07022 1.14518 8.20617 1.17383C8.35981 1.2062 8.50448 1.28887 8.79382 1.45421L10.7062 2.54698C10.9955 2.71231 11.1402 2.79499 11.2938 2.82736C11.4298 2.856 11.5702 2.856 11.7062 2.82736C11.8598 2.79499 12.0045 2.71231 12.2938 2.54698L12.6062 2.36849C13.3923 1.91929 13.7853 1.69469 14.1076 1.72958C14.3888 1.76002 14.6439 1.90807 14.8098 2.13707C15 2.39959 15 2.85229 15 3.75768V12.0006M15 11.0006H19V17.0006C19 18.1052 18.1046 19.0006 17 19.0006C15.8954 19.0006 15 18.1052 15 17.0006V11.0006Z'
        stroke='#6F6F6F'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </SvgIcon>
  )
}

export default ListIcon
