import { createDraftSafeSelector } from '@reduxjs/toolkit'

export const selectEnrolment = createDraftSafeSelector(
  state => state.enrolment,
  state => {
    return state
  }
)

export const selectOrder = createDraftSafeSelector(
  state => state.enrolment,
  state => state.order
)

export const selectProductOrders = createDraftSafeSelector(
  state => state.enrolment,
  state => state.order?.productOrders
)

export const selectLocationsByOrder = createDraftSafeSelector(
  state => state.enrolment,
  state => state.locationsByOrder
)

export const selectAgesByOrder = createDraftSafeSelector(
  state => state.enrolment,
  state => state.agesByOrder
)

export const selectSubjectsByOrder = createDraftSafeSelector(
  state => state.enrolment,
  state => state.subjectsByOrder
)

export const selectActiveProductOrderId = createDraftSafeSelector(
  state => state.enrolment,
  state => state.activeProductOrderId
)

export const selectEducationalModels = createDraftSafeSelector(
  state => state.enrolment,
  state => state.educationalModels
)

export const selectMainPackages = createDraftSafeSelector(
  state => state.enrolment,
  state => state.order?.productOrders[0]?.product?.packages
)

export const selectProgramDates = createDraftSafeSelector(
  state => state.enrolment,
  state => state.programDates
)

export const selectStudentDetails = createDraftSafeSelector(
  state => state.enrolment,
  state =>
    state.order.studentDetails || {
      firstName: '',
      lastName: '',
      email: '',
      dateOfBirth: '',
      gender: '',
      nationality: ''
    }
)

export const selectMainMainOrderId = createDraftSafeSelector(
  state => state.enrolment,
  state => state.order.id
)

export const selectPersonalDetails = createDraftSafeSelector(
  state => state.enrolment,
  state => state.order.studentDetails
)

export const selectCompletedFields = createDraftSafeSelector(
  state => state.enrolment.order,
  state => ({
    programOptions: !!state?.productOrders[0]?.programDate,
    additionalProgram: !!state?.productOrders[0]?.programDate,
    studentDetails: !!state?.studentDetails?.firstName && !!state?.studentDetails?.lastName,
    academicDetails: !!state?.studentDetails?.schoolName,
    parentGuardianDetails: !!state?.studentDetails?.parentFirstName && !!state?.studentDetails?.parentLastName
  })
)

export const selectStartPathwayScreen = createDraftSafeSelector(
  state => state.enrolment,
  state => state.startPathwayScreen
)

export const selectIsNewProgramAdded = createDraftSafeSelector(
  state => state.enrolment,
  state => state.isNewProgramAdded
)

export const selectIsDeposit = createDraftSafeSelector(
  state => state.enrolment,
  state => state.isDeposit
)

export const selectBillingAddress = createDraftSafeSelector(
  state => state.enrolment.order,
  state => state.billingAddress
)

export const selectIsMainProductSaved = createDraftSafeSelector(
  state => state.enrolment,
  state => state.isMainProductSaved
)

export const selectIsChangeModalOpen = createDraftSafeSelector(
  state => state.enrolment,
  state => state.isOpenChangeModal
)
