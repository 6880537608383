import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  stages: {
    enrolment: {
      completed: false,
      active: false,
      subStages: {
        personalDetails: {
          completed: false,
          active: false
        },
        courseSelection: {
          completed: false,
          active: false
        },
        extendYourCourse: {
          completed: false,
          active: false
        }
      }
    },
    studentDetails: { completed: false, active: false },
    payment: { completed: false, active: false },
    success: { completed: false, active: false }
  }
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setCompletedStage(state, { payload }) {
      state.stages[payload.stage].completed = payload.value
    },

    setActiveStage(state, { payload }) {
      state.stages[payload.stage].active = payload.value
    },

    setCompletedEnrolmentSubStage(state, { payload }) {
      state.stages.enrolment.subStages[payload.stage].completed = payload.value

      const { personalDetails, courseSelection, extendYourCourse } = state.stages.enrolment.subStages

      if (personalDetails.completed && courseSelection.completed && extendYourCourse.completed) {
        state.stages.enrolment.completed = true
      }
    },

    setActiveEnrolmentSubStage(state, { payload }) {
      state.stages.enrolment.subStages[payload.stage].active = payload.value
    }
  }
})

export const { setCompletedStage, setActiveStage, setCompletedEnrolmentSubStage, setActiveEnrolmentSubStage } =
  applicationSlice.actions

export default applicationSlice.reducer
