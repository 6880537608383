import { useState } from 'react'

import SearchIcon from '../../assets/icons/SearchIcon'
import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import Input from '../TextField'
import { StyledAutocomplete } from './Autocomplete.styled'

const Autocomplete = ({
  options,
  placeholder,
  onSelect,
  error,
  initialValue,
  initialInputValue,
  notFromOptions = false,
  checkOnReset = false,
  onInputChange,
  endAdornmentIcon,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(initialInputValue || '')

  return (
    <StyledAutocomplete
      disablePortal
      value={initialValue}
      options={options}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        onSelect(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)

        notFromOptions && onSelect(newInputValue)

        onInputChange && onInputChange(event, newInputValue)
      }}
      renderInput={params => {
        return (
          <Input
            {...params}
            value={params.inputProps.value}
            onChange={params.inputProps.onChange}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              className: '',
              endAdornment:
                endAdornmentIcon !== 'search' ? (
                  <SelectAngleIcon onClick={params.InputProps.onClick} />
                ) : (
                  <SearchIcon className='search-icon' onClick={params.InputProps.onClick} />
                )
            }}
            inputProps={{ ...params.inputProps, className: '' }}
            error={error}
          />
        )
      }}
      slotProps={{
        paper: { elevation: 8, sx: { backgroundColor: 'transparent' } },
        clearIndicator: { sx: { display: 'none' } }
      }}
      {...props}
    />
  )
}

export default Autocomplete
