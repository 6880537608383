export const transferOptionsValues = [
  {
    label: 'Pick-up + Return',
    value: 'Pick-up + Return',
    transferFrom: true,
    transferTo: true,
    mostPopular: true,
    price: 300
  },
  {
    label: 'Pick-up only',
    value: 'Pick-up only',
    transferFrom: true,
    transferTo: false,
    mostPopular: false,
    price: 150
  },
  { label: 'Return only', value: 'Return only', transferFrom: false, transferTo: true, mostPopular: false, price: 150 },
  { label: 'No (maybe later)', value: 'No (maybe later)', transferFrom: false, transferTo: false, mostPopular: false }
]
