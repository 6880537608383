import { Box } from '@mui/material'

import styled from 'styled-components'

export const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    ${({ theme }) => theme.typography.h1};
    font-weight: 600;

    @media (max-width: 1024px) {
      text-align: center;
      padding-top: 16px;
      font-size: 22px;
      font-weight: 500;
      line-height: 28.6px;
    }
  }

  .text {
    margin-top: 0.8rem;
    ${({ theme }) => theme.typography.textMain};
    line-height: 2.2rem;
    font-weight: 300;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  .text-field-label {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.typography.textSecondary};
    white-space: initial;
    margin-bottom: 0.8rem;

    &-error {
      color: #c8102e;
    }

    &-message-error {
      color: #c8102e;
      font-size: 12px;
      line-height: 18px;
      margin-top: 4px;
    }
  }

  .form {
    &-col {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;

      @media (max-width: 1024px) {
        width: 100%;
      }

      &-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
      }
    }

    &-grid-col {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    &-item {
      flex-basis: calc(50% - 0.8rem);

      @media (max-width: 768px) {
        flex-basis: 100%;
      }
    }

    &-dates {
      flex-basis: calc(50% - 0.8rem);

      @media (max-width: 768px) {
        flex-basis: 100%;
      }

      &-container {
        display: flex;
        gap: 0.8rem;
      }

      &-item {
        flex-basis: 33.33333%;
      }

      &-label {
        ${({ theme }) => theme.typography.textSecondary};
        margin-bottom: 0.8rem;

        &-error {
          color: #c8102e;
        }
      }
    }
  }

  .accordion {
    margin-top: 16px;
    border: 1px solid #d5dadf;
    border-radius: 16px;
    padding: 0;

    & .MuiCollapse-root {
      border-top: 1px solid #d5dadf;
    }
  }

  .accordion-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;

    &.Mui-expanded {
      height: 48px;
      min-height: 48px;
    }
  }

  .accordion-title {
    padding-left: 12px;
    color: #2d4960;
    font-size: 16px;
    font-weight: 450;
    line-height: 24px;

    &-price {
      color: #1d1d1d;
    }
  }

  .accordion-details {
    padding: 0;
  }

  .bordered-discount {
    border: 1px solid #d5dadf;
    border-radius: 8px;
    padding: 12px 16px;
  }
`
