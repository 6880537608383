import { axiosClassic } from '../api/api'

export const StudentService = {
  async addPersonalDetails(orderId, body) {
    const res = await axiosClassic.patch(`/order/${orderId}/personal-details`, body)

    return res.data
  },

  async addAcademicDetails(orderId, body) {
    const res = await axiosClassic.patch(`/order/${orderId}/academic-details`, body)

    return res.data
  },

  async addParentGuardianDetails(orderId, body) {
    const res = await axiosClassic.patch(`/order/${orderId}/parent-details`, body)

    return res.data
  }
}
