import React from 'react'

import { Box, Typography } from '@mui/material'

import CustomButton from '../../components/Button'

const NotFoundProduct = () => {
  const handleClickReturnButton = () => {
    window.location.href = 'https://www.immerse.education/select-programme/'
  }

  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      marginTop={8}
      marginBottom={20}
    >
      <Typography variant='h3' marginBottom={4}>
        This programme wasn't found
      </Typography>

      <CustomButton onClick={handleClickReturnButton}>Return to programme selection</CustomButton>
    </Box>
  )
}

export default NotFoundProduct
