import { Box, Typography } from '@mui/material'

import CrossMarkIcon from '../../assets/icons/CrossmarkIcon'
import { StyledPopup } from './styledPopup'

const PledgePopup = ({ isOpen, onClose }) => {
  return (
    <StyledPopup open={isOpen} onClose={onClose}>
      <Box className='guarantee-popup'>
        <Box className='guarantee-popup-content'>
          <Box className='guarantee-popup-title-box'>
            <Typography className='guarantee-popup-title' component='h3'>
              Empowered Learning Guarantee
            </Typography>
            <Box className='guarantee-popup-close' onClick={onClose}>
              <CrossMarkIcon />
            </Box>
          </Box>
          <Typography className='guarantee-popup-text'>
            We pledge that if our programmes haven't helped to improve your knowledge in your chosen subject area, we
            will provide you with an Immerse Online Programme entirely for free.
          </Typography>
        </Box>
      </Box>
    </StyledPopup>
  )
}

export default PledgePopup
