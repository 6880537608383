import { Box } from '@mui/material'

import styled from 'styled-components'

export const PriceBreakdownStyled = styled(Box)`
  min-width: 344px;

  @media (max-width: 1024px) {
    min-width: auto;
    margin: 16px 0;
  }

  .price-breakdown {
    border-left: 1px solid #dadada;
    padding: 0px 32px;
    padding-bottom: 32px;
    height: calc(100vh - 88px - 100px);
    overflow-y: auto;

    @media (max-width: 1024px) {
      border-left: none;
      padding: 0;
      height: auto;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #dadada;
      border: 4px solid #dadada;
      border-radius: 99999px;
    }

    h4 {
      color: #1d1d1d;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 12px;
    }

    &-card {
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background: #f5f5f7;
      padding: 16px;
      margin-bottom: 3px;

      hr {
        width: 100%;
        background-color: #dadada;
        margin: 0 0 16px 0;
      }

      ul {
        display: flex;
        flex-direction: column;
        margin: 0;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #1d1d1d;
          margin-bottom: 6px;
          gap: 12px;

          p {
            color: #1d1d1d;
            font-size: 14px;
            font-weight: 300;
            line-height: 145%;
          }

          span {
            color: #1d1d1d;
            text-align: right;
            font-size: 15px;
            font-weight: 300;
            line-height: 150%;
          }
          .bold {
            font-weight: 500;
            margin-bottom: -3px;
          }

          span.bold {
            letter-spacing: -0.5px;
          }

          .red {
            color: #ec1e32;
          }
        }

        .subtotal {
          padding-bottom: 16px;
          border-bottom: 1px solid #dadada;
          margin-bottom: 0px;
        }

        .first {
          padding-top: 16px;
        }

        hr {
          margin: 10px 0 16px;
        }

        li.total {
          padding: 16px 0;
          margin-bottom: 0;

          p,
          span {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
          }
        }
      }
    }

    div#paymentVariants {
      gap: 8px;

      & div label {
        padding: 8px 15px 8px 10px;
      }
    }

    .radio-buttons {
      position: relative;

      .tooltip {
        position: absolute;
        top: 79px;
        left: 147px;
      }
    }
  }
`
