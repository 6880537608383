import styled from 'styled-components'

export const ProgressList = styled('ul')`
  padding-left: 4px;
  margin: 44px 0 0;

  .progress-item {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1.2rem;
    ${({ theme }) => theme.typography.sidebar.headingGray};

    &:last-of-type {
      margin-bottom: 0;
    }

    &:not(:first-of-type) {
      .progress-item-text {
        line-height: 32px;
      }
    }

    @media (max-height: 668px) {
      font-size: 1.6rem;
      margin-bottom: 1.2rem;
    }

    &-text {
      display: flex;
      align-items: center;
      line-height: 28px;

      @media (max-height: 550px) {
        line-height: 2.6rem;
      }
    }

    &-marker {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.4rem;
      width: 2.4rem;
      height: 2.4rem;
      padding: 2px;
      border: 0.2rem solid;
      border-color: ${({ theme }) => theme.palette.text.gray2};
      border-radius: 100%;
      ${({ theme }) => theme.typography.textSecondary};
      color: ${({ theme }) => theme.palette.text.gray2};
      line-height: 1.4rem;
    }

    &-sub {
      margin-left: 3.8rem;
    }

    &-subtext {
      margin-top: 0.4rem;
      margin-left: 3.8rem;
      line-height: 140%;
      ${({ theme }) => theme.typography.sidebar.textGray}

      @media (max-height: 550px) {
        margin-top: 0rem;
      }
    }

    &-sub-list {
      margin: 1.2rem 0 0 1rem;

      @media (max-height: 668px) {
        margin: 0rem 0 0 0.8rem;
      }

      &-item {
        display: flex;
        align-items: center;
        ${({ theme }) => theme.typography.sidebar.textGray};
        font-weight: 450;
        line-height: 3.2rem;

        span {
          display: block;
          width: 0.8rem;
          height: 0.8rem;
          margin-right: 1.2rem;
          background-color: #1d1d1d;
          border-radius: 100%;
          opacity: 0.4;
        }

        &:not(:last-of-type) {
          margin-bottom: 4px;
        }
      }

      &-item-active {
        span {
          opacity: 1;
          background-color: #bb945c;
        }

        color: #1d1d1d;
      }

      &-item-completed {
        color: ${({ theme }) => theme.palette.text.gray2};

        svg {
          fill: none;
          margin: 0 0.6rem 0 -0.7rem;
        }
      }
    }
  }

  .progress-item-active {
    color: ${({ theme }) => theme.palette.text.black};

    .progress-item-marker {
      color: ${({ theme }) => theme.palette.text.black};
      border-color: ${({ theme }) => theme.palette.text.black};
    }
  }
  .progress-item-completed {
    color: ${({ theme }) => theme.palette.text.gray2};

    .progress-item-text {
      svg {
        margin-right: 1.4rem;
        border-radius: 10rem;
        background-color: ${({ theme }) => theme.palette.main.brown};

        path {
          stroke: ${({ theme }) => theme.palette.main.white};
        }
      }
    }
  }
`
