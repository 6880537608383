import { axiosClassic } from '../api/api'

export const CouponService = {
  async addCoupon(orderId, body) {
    const res = await axiosClassic.patch(`/order/${orderId}/coupon/add`, body)

    return res.data
  },

  async removeCoupon(orderId) {
    const res = await axiosClassic.patch(`/order/${orderId}/coupon/remove`)

    return res.data
  }
}
