import cn from 'classnames'
import { useState } from 'react'
import { useEffect } from 'react'
import { memo } from 'react'

import { Box, Button, IconButton, Typography } from '@mui/material'

import ChevronLeft from '../../assets/icons/ChevronLeft'
import ChevronRight from '../../assets/icons/ChevronRight'
import CrossMarkIcon from '../../assets/icons/CrossmarkIcon'
import { monthsData } from '../../constants/months'
import CustomButton from '../Button'
import { StyledModal } from './styled'

const currentYear = new Date().getFullYear()
const nextYear = currentYear + 1
const currentTime = Date.now()
const getTimestampFromYearAndMonth = (year, month) => new Date(year, month).getTime()

const DateModal = memo(({ open, onClose, onSubmit, programDate, ...props }) => {
  const [currentYearMonths, setCurrentYearMonths] = useState(
    monthsData.map(item => ({ ...item, value: `${item.value} ${currentYear}` }))
  )
  const [nextYearMonths, setNextYearMonths] = useState(
    monthsData.map(item => ({ ...item, value: `${item.value} ${nextYear}` }))
  )
  const [programDateMonth, setProgramDateMonth] = useState(null)

  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [totalCurrentSelected, setTotalCurrentSelected] = useState([])
  const [totalNextSelected, setTotalNextSelected] = useState([])

  const handleYearChange = newYear => {
    setSelectedYear(newYear)
  }

  const handleMonthSelect = event => {
    if (selectedYear === currentYear) {
      const newValues = currentYearMonths.map(item => {
        if (item.value === event.target.id) {
          return { ...item, selected: !item.selected }
        }
        return item
      })
      setCurrentYearMonths(newValues)
    } else {
      const newValues = nextYearMonths.map(item => {
        if (item.value === event.target.id) {
          return { ...item, selected: !item.selected }
        }
        return item
      })
      setNextYearMonths(newValues)
    }
  }

  useEffect(() => {
    setTotalCurrentSelected(currentYearMonths.filter(item => item.selected))
    setTotalNextSelected(nextYearMonths.filter(item => item.selected))
  }, [currentYearMonths, nextYearMonths])

  useEffect(() => {
    const dateMonth = new Date(programDate?.startDate).toLocaleString('en-US', { month: 'short' })
    setProgramDateMonth(dateMonth)
  }, [programDate])

  const handleSubmit = () => {
    onSubmit(currentYearMonths, nextYearMonths)
  }

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      {...props}
    >
      <Box className='modal-body'>
        <Box className='modal-header'>
          <Typography className='modal-title'>Choose month</Typography>
          <IconButton onClick={onClose} className='close-button'>
            <CrossMarkIcon />
          </IconButton>
        </Box>
        <Box className='modal-year'>
          <IconButton onClick={() => handleYearChange(currentYear)}>
            <ChevronLeft fill={selectedYear === nextYear ? '#6F6F6F' : '#C9C9C9'} />
          </IconButton>
          <Typography className='modal-year-label'>{selectedYear}</Typography>
          <IconButton onClick={() => handleYearChange(nextYear)}>
            <ChevronRight fill={selectedYear === nextYear ? '#C9C9C9' : '#6F6F6F'} />
          </IconButton>
        </Box>
        <Box className='modal-divider' />
        <Box className='modal-months'>
          {selectedYear === currentYear
            ? currentYearMonths.map((item, idx) => (
                <Button
                  disabled={
                    getTimestampFromYearAndMonth(
                      currentYear,
                      currentYearMonths.find(({ label }) => label === item.label).number
                    ) < currentTime ||
                    (totalCurrentSelected.length + totalNextSelected.length > 2 &&
                      !totalCurrentSelected.find(option => option.label === item.label)) ||
                    programDateMonth === item.label
                  }
                  key={idx}
                  onClick={handleMonthSelect}
                  id={item.value}
                  className={cn('modal-month-button', { isSelected: item.selected })}
                >
                  {item.label}
                </Button>
              ))
            : nextYearMonths.map((item, idx) => (
                <Button
                  key={idx}
                  onClick={handleMonthSelect}
                  disabled={
                    (totalCurrentSelected.length + totalNextSelected.length > 2 &&
                      !totalNextSelected.find(option => option.label === item.label)) ||
                    programDateMonth === item.label
                  }
                  id={item.value}
                  className={cn('modal-month-button', { isSelected: item.selected })}
                >
                  {item.label}
                </Button>
              ))}
        </Box>
        <Box className='modal-buttons'>
          <CustomButton
            onClick={onClose}
            sx={{
              width: '133px',
              minWidth: 0,
              height: '52.5px',
              borderRadius: '52px',
              padding: '14px 32px 14px 32px',
              backgroundColor: '#F5F5F7',
              color: '#6F6F6F',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '24px',
              '&:hover': {
                color: '#1D1D1D',
                backgroundColor: '#fff',
                boxShadow: 'none'
              }
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={handleSubmit}
            disabled={totalCurrentSelected.length + totalNextSelected.length === 0}
            sx={{
              width: '133px',
              minWidth: 0,
              height: '52.5px',
              borderRadius: '52px',
              padding: '14px 32px 14px 32px',
              backgroundColor: '#181C2A',
              color: '#ffffff',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '24px',

              '&.Mui-disabled': {
                color: '#ffffff',
                backgroundColor: 'rgba(24, 28, 42, 0.3)'
              }
            }}
          >
            Apply
          </CustomButton>
        </Box>
      </Box>
    </StyledModal>
  )
})

export default DateModal
