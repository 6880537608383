import cn from 'classnames'
import { useLocation } from 'react-router-dom'

import CheckmarkIcon from '../../../assets/icons/CheckmarkIcon'
import { selectCompletedFields } from '../../../store/enrolment/enrolment.selectors'
import { progressConfig } from './progress.config'
import { StyledProgressBar } from './styledProgressBar'

import { useSelector } from 'react-redux'

const MobileProgressBar = props => {
  const { pathname } = useLocation()

  const completedFields = {
    ...useSelector(selectCompletedFields),
    programSummary: pathname === '/payment' || pathname === '/success',
    payment: pathname === '/success'
  }

  return (
    <StyledProgressBar {...props}>
      {progressConfig.map(({ id, text, subList, childrenPaths, field }, key) => (
        <li
          key={id}
          className={cn('progress-item', {
            'progress-item-active': childrenPaths.includes(pathname),
            'progress-item-completed':
              (!childrenPaths.includes(pathname) && subList && subList.every(({ field }) => completedFields[field])) ||
              pathname === '/success'
          })}
        >
          {(!childrenPaths.includes(pathname) && subList && subList.every(({ field }) => completedFields[field])) ||
          (pathname === '/success' && field !== 'success') ? (
            <CheckmarkIcon />
          ) : (
            <span className='progress-item-marker'>{key + 1}</span>
          )}
          <p className='progress-item-text'>{text}</p>
          {key < 3 && <div className='progress-line'></div>}
        </li>
      ))}
    </StyledProgressBar>
  )
}

export default MobileProgressBar
