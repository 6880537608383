import { SvgIcon } from '@mui/material'

const TelIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ width: 24, height: 24, fill: 'none !important', ...sx }}
      {...props}
      viewBox='0 0 24 24'>
      <path
        d='M17.2681 14.585L13.8364 15.25C11.5181 14.0867 10.0864 12.75 9.25309 10.6667L9.89475 7.225L8.68225 4H5.55642C4.61642 4 3.87642 4.77667 4.01725 5.70583C4.36725 8.025 5.40059 12.2308 8.41975 15.25C11.5906 18.4208 16.1581 19.7967 18.6714 20.3442C19.6423 20.555 20.5031 19.7983 20.5031 18.8042V15.8175L17.2681 14.585Z'
        stroke='#6F6F6F'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  )
}

export default TelIcon
