import { SvgIcon } from '@mui/material'

const MobileShieldIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon
      sx={{ fill: 'none !important', width: '22px', height: '26px', ...sx }}
      viewBox='0 0 22 26'
      fill='none'
      {...props}>
      <path
        d='M11.0001 26C11.0001 26 21.6365 20.8 21.6365 13V3.9L11.0001 0L0.36377 3.9V13C0.36377 20.8 11.0001 26 11.0001 26Z'
        fill='#BB945C'
      />
      <path
        d='M6.27295 11.9871L9.71097 15.3637L15.7275 9.45459'
        stroke='white'
        strokeWidth='2.36364'
      />
    </SvgIcon>
  )
}

export default MobileShieldIcon
