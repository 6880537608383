import React, { useState } from 'react'

import { Box } from '@mui/material'

import { phoneCodes } from '../../constants/phoneCodes'
import Autocomplete from '../Autocomplete'
import { StyledPhoneInput } from './styled'

const formattedPhones = phoneCodes.map(({ code, phone }) => ({ label: phone, code }))

const PhoneInput = ({ initialValue = '', initialCode = '', onChange, ...props }) => {
  const [code, setCode] = useState(initialCode)

  const selectHandler = option => {
    setCode(option.code)
  }

  const changePhone = e => {
    if (e) {
      onChange(e?.label || e)
    }
  }

  return (
    <StyledPhoneInput {...props}>
      {code && (
        <img
          loading='lazy'
          width='24'
          height='18'
          srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
          src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
          alt={`${code} flag`}
        />
      )}

      <Autocomplete
        options={formattedPhones}
        initialValue={initialValue}
        initialInputValue={initialValue}
        onSelect={changePhone}
        error={props.error}
        onInputChange={(event, newInputValue) => {
          const foundedNumber = phoneCodes.find(({ phone }) => phone === newInputValue)
          setCode(foundedNumber?.code || '')
        }}
        notFromOptions
        renderOption={(props, option) => (
          <Box
            component='li'
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
            key={option.code + option.label}
            onClick={e => {
              selectHandler(option)
              props.onClick(e)
            }}
          >
            <img
              loading='lazy'
              width='24'
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=''
            />
            {option.label}
          </Box>
        )}
      />
    </StyledPhoneInput>
  )
}

export default PhoneInput
