import { Box, Button, Typography } from '@mui/material'

import { Wrapper } from './styled'

const EducationProgrammeBox = ({
  onClick,
  subject,
  age,
  location,
  educationModel,
  model,
  date,
  childProductOrder,
  packageDescription,
  programDate,
  programAge,
  prefferedMonths
}) => {
  return (
    <Wrapper>
      {location === 'Online' ? (
        <Box className='education-info-wrapper'>
          <Box className='education-header-info-box'>
            <Typography className='education-info-header'>{subject?.replace(/&amp;/g, '&')}</Typography>
            <Button onClick={onClick} className='education-edit-button'>
              Edit
            </Button>
          </Box>
          <Box className='education-info-box'>
            {model && (
              <>
                <Typography className='education-info-text'>{model}</Typography>
                <Box className='dot' />
              </>
            )}
            <Typography className='education-info-text'>{(packageDescription || '').replace('•', '')}</Typography>
          </Box>
          <Box className='education-info-box'>
            <Typography className='education-info-text'>{educationModel}</Typography>
          </Box>
          <Box className='education-info-box'>
            {programDate ? (
              <>
                <Typography className='education-info-text'>{programDate.replace('•', '')}</Typography>
                <Box className='dot' />
              </>
            ) : (
              <>
                <Typography className='education-info-text'>
                  {prefferedMonths?.map(item => item.month).join(', ')}
                </Typography>
                <Box className='dot' />
              </>
            )}
            <Typography className='education-info-text'>{age}</Typography>
          </Box>
          {childProductOrder && (
            <>
              <Box className='education-header-info-box'>
                <Typography className='education-info-header'>
                  {childProductOrder?.subject?.title.replace(/&amp;/g, '&')} ({childProductOrder?.age?.title})
                </Typography>
              </Box>
              <Box className='education-info-box'>
                <Typography className='education-info-text'>{childProductOrder?.educationalModel?.title}</Typography>
              </Box>
              <Box className='education-info-box'>
                <Typography className='education-info-text'>
                  {childProductOrder?.package?.classification?.title}
                </Typography>
                <Box className='dot' />
                <Typography className='education-info-text'>{childProductOrder?.product?.dateDuration}</Typography>
                <Box className='dot' />
                <Typography className='education-info-text'>
                  {childProductOrder?.preferredMonths?.map(item => item.month).join(', ')}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Box className='education-info-wrapper'>
          <Box className='education-header-info-box'>
            <Typography className='education-info-header'>
              {subject?.replace(/&amp;/g, '&')} ({age})
            </Typography>
            <Button onClick={onClick} className='education-edit-button'>
              Edit
            </Button>
          </Box>
          <Box className='education-info-box'>
            {educationModel && (
              <>
                <Typography className='education-info-text'>{educationModel}</Typography>
                <Box className='dot' />
              </>
            )}
            <Typography className='education-info-text'>{location}</Typography>
          </Box>
          <Box className='education-info-box'>
            <Typography className='education-info-text'>{model}</Typography>
            <Box className='dot' />
            <Typography className='education-info-text'>{date}</Typography>
          </Box>
          {childProductOrder && (
            <>
              <Box className='education-header-info-box'>
                <Typography className='education-info-header'>
                  {childProductOrder?.subject?.title.replace(/&amp;/g, '&')} ({childProductOrder?.age?.title})
                </Typography>
              </Box>
              <Box className='education-info-box'>
                <Typography className='education-info-text'>{childProductOrder?.educationalModel?.title}</Typography>
              </Box>
              <Box className='education-info-box'>
                <Typography className='education-info-text'>
                  {childProductOrder?.package?.classification?.title}
                </Typography>
                <Box className='dot' />
                <Typography className='education-info-text'>
                  {(childProductOrder?.package?.description || '').replace('•', '')}
                </Typography>
                <Box className='dot' />
                <Typography className='education-info-text'>
                  {childProductOrder?.preferredMonths?.map(item => item.month).join(', ')}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </Wrapper>
  )
}

export default EducationProgrammeBox
