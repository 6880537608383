import { Box, Link, Typography } from '@mui/material'

import CrossMarkIcon from '../../assets/icons/CrossmarkIcon'
import LetterIcon from '../../assets/icons/LetterIcon'
import { StyledPopup } from './styledPopup'

const EmailPopup = ({ isOpen, onClose }) => {
  return (
    <StyledPopup open={isOpen} onClose={onClose}>
      <Box className='guarantee-popup'>
        <Box className='guarantee-popup-content'>
          <Box className='guarantee-popup-title-box'>
            <Typography className='guarantee-popup-title' component='h3'>
              Speak with a Consultant
            </Typography>
            <Box className='guarantee-popup-close' onClick={onClose}>
              <CrossMarkIcon />
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Link
              className='header-consult-link'
              sx={{ color: '#BB945C', fontWeight: 300 }}
              href='mailto:advisor@immerse.education'
            >
              <LetterIcon />
              <Typography className='link-text'>advisor@immerse.education</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </StyledPopup>
  )
}

export default EmailPopup
