import cn from 'classnames'

import { MenuItem } from '@mui/material'

import SelectAngleIcon from '../../assets/icons/SelectAngleIcon'
import Input from '../TextField'
import { StyledSelect } from './Select.styled'

// custom search + select dropdown component
const Select = ({ options, label, placeholder, className, onChange, error, isEducationModel, ...props }) => {
  const handleOptionChange = event => {
    const value = event.target.value

    onChange(value)
  }

  return (
    <StyledSelect className={cn({ [className]: true })}>
      <Input
        select
        value={props.value}
        onChange={handleOptionChange}
        label={label}
        className='select'
        error={error}
        {...props}
        SelectProps={{
          displayEmpty: true,
          renderValue: () => props.value || <span style={{ color: error ? '#C8102E' : '#8F8F8F' }}>{placeholder}</span>,
          IconComponent: SelectAngleIcon,
          MenuProps: {
            className: 'select-menu',

            slotProps: {
              paper: {
                sx: {
                  backgroundColor: '#fff',
                  maxHeight: isEducationModel ? '400px' : '200px',
                  border: `1px solid ${error ? '#C8102E' : '#C0C8CF'}`,
                  borderRadius: '8px',
                  borderTop: 'none',
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,

                  '&::-webkit-scrollbar': {
                    width: '4px'
                  },

                  '&::-webkit-scrollbar-track': {
                    background: 'transparent'
                  },

                  '&::-webkit-scrollbar-thumb': {
                    background: '#dadada',

                    border: '4px solid #dadada',

                    borderRadius: '99999px'
                  }
                }
              }
            },
            MenuListProps: {
              sx: {
                width: '100%',
                backgroundColor: '#fff',
                marginTop: 0,
                border: 'none',
                padding: 0,

                '.MuiMenuItem-root': {
                  borderRadius: 0,
                  padding: isEducationModel ? '4px 8px' : '8px'
                }
              }
            }
          }
        }}
      >
        {options.map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </Input>
    </StyledSelect>
  )
}

export default Select
