import { SvgIcon } from '@mui/material'

const CheckedIcon = ({ sx = {}, ...props }) => {
  return (
    <SvgIcon sx={{ width: 24, height: 24, fill: 'none', ...sx }} {...props} viewBox='0 0 24 24'>
      <circle cx='12' cy='12' r='12' fill='#B8945C' />
      <path d='M6 12L10 16L18 8' stroke='white' strokeWidth='3' />
    </SvgIcon>
  )
}

export default CheckedIcon
