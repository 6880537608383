import styled from 'styled-components'

export const StyledProgressBar = styled.ul`
  margin-top: 1.8rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  overflow: -o-scrollbars-none;
  -webkit-overflow-scrolling: touch;

  .progress-item {
    position: relative;
    left: 60px;
    width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-text {
      width: 115px;
      text-align: center;
      margin-top: 1rem;
      line-height: 1.96rem;
      ${({ theme }) => theme.typography.h6};
      color: #a1a1a1;
      font-weight: 500;
    }

    &-marker {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      border: 0.2rem solid;
      border-color: ${({ theme }) => theme.palette.text.gray2};
      border-radius: 100%;
      ${({ theme }) => theme.typography.textSecondary};
      color: ${({ theme }) => theme.palette.text.gray2};
      line-height: 1.4rem;
    }
  }

  .progress-line {
    position: absolute;
    top: 1.3rem;
    right: 0;
    transform: translateX(50%);
    width: 7.4rem;
    height: 0.1rem;
    background-color: #b6b6b7;
  }

  .progress-item-active {
    .progress-item-text {
      color: ${({ theme }) => theme.palette.text.black};
    }

    .progress-item-marker {
      color: ${({ theme }) => theme.palette.text.black};
      border-color: ${({ theme }) => theme.palette.text.black};
    }
  }
  .progress-item-completed {
    color: ${({ theme }) => theme.palette.text.gray2};

    svg {
      border-radius: 10rem;
      background-color: ${({ theme }) => theme.palette.main.brown};

      path {
        stroke: ${({ theme }) => theme.palette.main.white};
      }
    }

    .progress-line {
      background-color: ${({ theme }) => theme.palette.main.brown};
    }
  }
`
