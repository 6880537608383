import { SvgIcon } from '@mui/material'

const ChevronRight = ({ sx = {}, fill, ...props }) => {
  return (
    <SvgIcon sx={{ fill: 'none !important', width: 9, height: 15, ...sx }} {...props} viewBox='0 0 9 15'>
      <path
        d='M0.307517 0.687644C0.213788 0.780607 0.139394 0.891208 0.0886254 1.01307C0.0378569 1.13493 0.0117189 1.26563 0.0117189 1.39764C0.0117189 1.52966 0.0378569 1.66036 0.0886254 1.78222C0.139394 1.90408 0.213788 2.01468 0.307517 2.10764L4.88751 6.68764C4.98124 6.78061 5.05563 6.89121 5.1064 7.01307C5.15717 7.13493 5.18331 7.26563 5.18331 7.39764C5.18331 7.52966 5.15717 7.66036 5.1064 7.78222C5.05563 7.90408 4.98124 8.01468 4.88751 8.10764L0.307516 12.6876C0.213788 12.7806 0.139394 12.8912 0.0886253 13.0131C0.0378567 13.1349 0.0117188 13.2656 0.0117188 13.3976C0.0117188 13.5297 0.0378567 13.6604 0.0886253 13.7822C0.139394 13.9041 0.213788 14.0147 0.307516 14.1076C0.494879 14.2939 0.74833 14.3984 1.01251 14.3984C1.2767 14.3984 1.53015 14.2939 1.71751 14.1076L6.3075 9.51764C6.86931 8.95514 7.18486 8.19265 7.18486 7.39764C7.18486 6.60264 6.86931 5.84015 6.3075 5.27764L1.71751 0.687644C1.53015 0.501393 1.2767 0.39685 1.01252 0.39685C0.74833 0.39685 0.494879 0.501393 0.307517 0.687644Z'
        fill={fill}
      />
    </SvgIcon>
  )
}

export default ChevronRight
