import { FormControl } from '@mui/material'

import styled from 'styled-components'

export const StyledRadioButton = styled(FormControl)`
  display: flex;

  .radio-buttons {
    &-button {
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 82px;
      padding: 14px 26px 16px 8px;
      background-color: #f5f5f7;
      border-radius: 16px;
      margin: 0;
      border: 2px solid transparent;

      @media (max-width: 1024px) {
        height: fit-content;
        padding: 16px;
      }

      &.isBundle {
        @media (max-width: 1024px) {
          height: 145px;
        }
      }

      &.checked {
        border: 2px solid;
        border-color: #bb945c;
        background-color: #f1eee9;

        & .Mui-checked {
          background-color: #bb945c;
          width: 24px;
          height: 24px;
          margin: 4px 9px;
        }
      }

      & .MuiTypography-root {
        display: contents;
      }

      & .MuiRadio-root {
        padding: 4px 9px 4px 9px;
      }
    }

    &-content-wrapper {
      width: 100%;
      margin-left: 10px;
    }

    &-content-heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &-content-heading-box {
      display: flex;
      gap: 16px;
    }

    &-content-header {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #181c2a;

      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &-content-discount {
      width: 65px;
      height: 20px;
      border-radius: 16px 0px 16px 0px;
      padding: 1px 11px 1px 11px;
      background-color: #bb945c;
    }

    &-content-discount-text {
      font-weight: 450;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }

    &-features-box {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1024px) {
        ${'' /* align-items: flex-start; */}
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }

    &-content-features {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #1d1d1d;
      white-space: nowrap;
    }

    &-content-tooltip {
      padding-top: 2px;
      &:hover {
        background-color: transparent;
      }
    }

    &-content-details {
      margin-top: 9.5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1024px) {
        gap: 12px;
      }
    }

    &-content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      @media (max-width: 1024px) {
        display: block;
      }
    }

    &-content-info {
      font-weight: 450;
      font-size: 14px;
      line-height: 18px;
      color: #6f6f6f;
    }

    &-content-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #bb945c;
    }

    &-content-duration {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #1d1d1d;
    }

    &-content-price-box {
      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 4px;
      }
    }

    &-content-discount-price {
      display: inline-block !important;
      margin-right: 12px;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #6f6f6f;
      text-decoration-line: line-through;
      @media (max-width: 1024px) {
        margin-right: 0px;
      }
    }

    &-content-price {
      display: inline-block !important;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #181c2a;
      @media (max-width: 1024px) {
        margin-left: auto;
      }
    }
  }
`
