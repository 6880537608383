import React, { memo, useEffect, useState } from 'react'
import { useRef } from 'react'

import { Box, IconButton, InputAdornment, Skeleton, Typography, useMediaQuery } from '@mui/material'

import ArrowTopRight from '../../assets/icons/ArrowTopRight'
import CalendarIcon from '../../assets/icons/CalendarIcon'
import CheckedIcon from '../../assets/icons/CheckedIcon'
import CircleIcon from '../../assets/icons/CircleIcon'
import CrossMarkIcon from '../../assets/icons/CrossmarkIcon'
import {
  abandonOrder,
  addPackage,
  addProgramDate,
  addTransferDetails,
  discardProductOrder,
  getAgesByOrder,
  getEducationalModelsByProductOrder,
  getLocationsByOrder,
  getProgramDatesByProductOrder,
  getSubjectsByOrder,
  selectAge,
  selectChildSubject,
  selectEducationalModel,
  selectLocation,
  selectPreferredChildMonths,
  selectPreferredMonths,
  selectSubject,
  validateProductOrder
} from '../../store/enrolment/enrolment.actions'
import {
  selectActiveProductOrderId,
  selectIsMainProductSaved,
  selectOrder,
  selectProductOrders
} from '../../store/enrolment/enrolment.selectors'
import CustomButton from '../Button'
import DateModal from '../DateModal'
import List from '../List'
import RadioButtonGroup from '../RadioButtonGroup'
import RadioButtonsGroup from '../RadioButtonGroup'
import Select from '../Select'
import Input from '../TextField'
import Toast from '../Toast'
import { StyledChangeProgrammeModal } from './styled'

import { useDispatch, useSelector } from 'react-redux'

const getClassifications = (order, productId, checkBy = 'order') => {
  const product = checkBy === 'order' ? order.productOrders.find(({ id }) => id === productId) : order

  if (product) {
    const classifications = product.product?.packages?.map(item => {
      const updatedTextArray = item?.listOfFeatures.map((text, index) => {
        return <span key={index} dangerouslySetInnerHTML={{ __html: text }} />
      })

      return {
        value: item.id,
        label: item.classification.title,
        isDiscount: !!item.discount,
        discount: item.discount,
        tooltipWidth: 455,
        info: item.packageTitle,
        duration: item.description,
        discountPrice: item.discountPrice,
        price: item.price,
        tooltip: (
          <List
            header={item.featuresTitle}
            options={updatedTextArray.map(item => ({
              component: <Typography className='list-text'>{item}</Typography>
            }))}
          />
        )
      }
    })

    return classifications
  }
  return []
}

const ChangeProgrammeModal = memo(({ productOrderId, ...props }) => {
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  const ref = useRef(null)

  const productOrders = useSelector(selectProductOrders)
  const product = productOrders.find(({ id }) => id === productOrderId)
  const { id } = useSelector(selectOrder)
  const isMainProductSaved = useSelector(selectIsMainProductSaved)
  const activeProductId = useSelector(selectActiveProductOrderId)

  const isMainProduct = productOrders[0]?.id === productOrderId

  const transferOptionsData = [
    {
      label: 'Pick-up + Return',
      value: 'Pick-up + Return',
      transferFrom: true,
      transferTo: true,
      mostPopular: true,
      price: product?.location?.airportTransferPrice && product?.location?.airportTransferPrice * 2
    },
    {
      label: 'Pick-up only',
      value: 'Pick-up only',
      transferFrom: true,
      transferTo: false,
      mostPopular: false,
      price: product?.location?.airportTransferPrice
    },
    {
      label: 'Return only',
      value: 'Return only',
      transferFrom: false,
      transferTo: true,
      mostPopular: false,
      price: product?.location?.airportTransferPrice
    },
    {
      label: 'No (maybe later)',
      value: 'No (maybe later)',
      transferFrom: false,
      transferTo: false,
      mostPopular: false
    }
  ]

  const transferOptions = transferOptionsData.find(
    item => item.transferFrom === product?.transferFrom && item.transferTo === product?.transferTo
  )?.value

  const onlineSubject = product?.childProductOrder?.subject?.title.replace(/&amp;/g, '&')

  const parentMonths = product?.preferredMonths?.map(item => `${item.month} ${item.year}`)

  const months = product?.childProductOrder?.preferredMonths?.map(item => `${item.month} ${item.year}`)

  const [isLoading, setIsLoading] = useState(true)
  const [isItemsLoading, setIsItemsLoading] = useState(false)

  const [locations, setLocations] = useState([])
  const [ages, setAges] = useState([])
  const [dates, setDates] = useState([])
  const [educationalModels, setEducationalModels] = useState([])
  const [subjects, setSubjects] = useState([])
  const [classifications, setClassifications] = useState([])
  const [childSubjects, setChildSubjects] = useState([])
  const [isOpenDateModal, setIsOpenDateModal] = useState(false)
  const [selectWidth, setSelectWidth] = useState(null)
  const [isDateLoading, setIsDateLoading] = useState(false)

  const handleLocationChange = value => {
    const locationId = locations.find(item => item.title === value).id
    setSubjects([])
    setAges([])
    setDates([])
    setEducationalModels([])
    setClassifications([])
    setChildSubjects([])
    setIsItemsLoading(true)

    dispatch(
      selectLocation({
        productOrderId,
        body: { locationId: locationId }
      })
    )
      .then(() => {
        dispatch(getSubjectsByOrder({ productOrderId })).then(({ payload }) => {
          setSubjects(payload)
        })
        dispatch(getAgesByOrder({ productOrderId })).then(({ payload }) => setAges(payload))
        dispatch(getEducationalModelsByProductOrder({ productOrderId })).then(({ payload }) =>
          setEducationalModels(payload)
        )
      })
      .then(() => setIsItemsLoading(false))
      .catch(() => {})
  }

  const handleAgeChange = event => {
    const age = event.target.value
    setDates([])

    dispatch(
      selectAge({
        productOrderId: productOrderId,
        body: { ageId: age }
      })
    )
      .then(() => {
        dispatch(getSubjectsByOrder({ productOrderId })).then(({ payload }) => {
          setSubjects(payload)
        })
      })
      .catch(() => {})
  }

  const handleEducationalModelChange = value => {
    setDates([])

    const educationalModelId = educationalModels.find(
      item => item.title === value.props.children[0].props.children[0].props.children[1].props.children
    )?.id

    dispatch(
      selectEducationalModel({
        productOrderId,
        body: JSON.stringify({ modelId: educationalModelId })
      })
    )
      .then(() => {
        dispatch(getSubjectsByOrder({ productOrderId })).then(({ payload }) => {
          setSubjects(payload)
        })
      })
      .catch(() => {})
  }

  const handleSubjectChange = value => {
    setDates([])

    const subjectId = subjects?.find(item => item?.title?.replace(/&amp;/g, '&') === value).id

    dispatch(
      selectSubject({
        productOrderId: productOrderId,
        body: { subjectId: subjectId }
      })
    )
      .then(({ payload }) => {
        setClassifications(getClassifications(payload, productOrderId))
      })
      .catch(() => {})
  }

  const handleClassificationValueChange = event => {
    const packageId = event.target.value

    setIsDateLoading(true)

    dispatch(
      addPackage({
        productOrderId: productOrderId,
        body: { packageId }
      })
    )
      .then(({ payload }) => {
        const activeProductOrder = payload?.productOrders?.find(item => item.id === productOrderId)
        console.log(activeProductOrder)

        dispatch(getProgramDatesByProductOrder({ productOrderId: activeProductOrder.id })).then(({ payload }) => {
          const dates = payload
            ? payload?.map(item => {
                return {
                  label: item.title,
                  value: item.id,
                  isSoldOut: item.isSoldOut
                }
              })
            : []
          setDates(dates)
        })

        setIsDateLoading(false)

        if (activeProductOrder?.childProductOrder) {
          dispatch(getSubjectsByOrder({ productOrderId: activeProductOrder?.childProductOrder?.id })).then(
            ({ payload }) => {
              setChildSubjects(payload)
            }
          )
        }
      })
      .catch(() => {})
  }

  const handleDateValueChange = event => {
    const programDateId = event.target.value

    dispatch(
      addProgramDate({
        productOrderId,
        body: { programDateId }
      })
    )
  }

  const handleTransferOptionsChange = event => {
    const option = transferOptionsData.find(item => item.value === event.target.value)

    dispatch(
      addTransferDetails({
        productOrderId,
        body: { transferTo: option.transferTo, transferFrom: option.transferFrom }
      })
    )
  }

  const handleOnlineSubjectChange = value => {
    const subjectId = childSubjects.find(item => item.title.replace(/&amp;/g, '&') === value).id

    dispatch(
      selectChildSubject({
        productOrderId,
        body: { subjectId }
      })
    )
  }

  const handleMonthsSubmit = (currentYearMonths, nextYearMonths) => {
    const currentYearValues = currentYearMonths.filter(item => item.selected).map(item => item.value)
    const nextYearValues = nextYearMonths.filter(item => item.selected).map(item => item.value)
    const values = [
      ...currentYearValues.map(item => ({ month: item.slice(0, 3), year: +item.slice(4, 8) })),
      ...nextYearValues.map(item => ({ month: item.slice(0, 3), year: +item.slice(4, 8) }))
    ]

    handleOpenDateModal()
    if (!product?.childProductOrder && !dates.length) {
      dispatch(
        selectPreferredMonths({
          productOrderId,
          body: JSON.stringify({ preferredMonths: values })
        })
      )
    } else {
      dispatch(
        selectPreferredChildMonths({
          productOrderId: productOrderId,
          body: { preferredMonths: values }
        })
      )
    }
  }

  const handleOpenDateModal = () => setIsOpenDateModal(isOpenDateModal => !isOpenDateModal)

  const handleDiscardChanges = () => {
    dispatch(discardProductOrder({ productOrderId })).then(({ payload }) => {
      dispatch(getProgramDatesByProductOrder({ productOrderId: activeProductId })).then(() => props.onClose())
    })
  }

  const handleSubmit = () => {
    dispatch(
      validateProductOrder({
        productOrderId
      })
    ).then(({ payload }) => {
      dispatch(getProgramDatesByProductOrder({ productOrderId: activeProductId })).then(() => props.onClose())
    })
  }

  const resetOrder = () => {
    dispatch(abandonOrder({ orderId: id }))
    window.location.href = 'https://www.immerse.education/select-programme/'
  }

  useEffect(() => {
    if (productOrderId) {
      dispatch(getLocationsByOrder({ productOrderId })).then(({ payload }) => setLocations(payload))
      dispatch(getAgesByOrder({ productOrderId })).then(({ payload }) => setAges(payload))
      dispatch(getEducationalModelsByProductOrder({ productOrderId })).then(({ payload }) =>
        setEducationalModels(payload)
      )
      dispatch(getSubjectsByOrder({ productOrderId })).then(({ payload }) => setSubjects(payload))
      if (product?.package) {
        dispatch(getProgramDatesByProductOrder({ productOrderId }))
          .then(({ payload }) => {
            const dates = payload
              ? payload?.map(item => {
                  return {
                    label: item.title,
                    value: item.id,
                    isSoldOut: item.isSoldOut
                  }
                })
              : []
            setDates(dates)
          })
          .then(() => setIsLoading(false))
      }

      setIsLoading(false)
    }
  }, [productOrderId, dispatch])

  useEffect(() => {
    if (product?.product?.packages && !classifications.length) {
      setClassifications(getClassifications(product, productOrderId, 'product'))
    }
    if (product?.childProductOrder) {
      dispatch(getSubjectsByOrder({ productOrderId: product?.childProductOrder?.id })).then(({ payload }) => {
        setChildSubjects(payload)
      })
    }
  }, [product])

  useEffect(() => {
    setSelectWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])

  const isDisabledSubmitButton = product?.childProductOrder
    ? !product.location.title ||
      !product?.age?.id ||
      !product?.subject?.title ||
      !product?.package?.id ||
      !product?.childProductOrder?.subject?.id ||
      !product?.childProductOrder?.preferredMonths
    : !product.location.title ||
      (product.location.title === 'Online' ? !product?.educationalModel?.id : !product?.age?.id) ||
      !product?.subject?.title ||
      !product?.package?.id ||
      (!!dates.length && !product?.programDate?.id) ||
      (product.location.title !== 'Online' &&
        product?.package?.classification?.title?.toLowerCase() !== 'non - residential' &&
        product?.location?.airportTransferPrice &&
        product?.transferFrom === null)

  return (
    <StyledChangeProgrammeModal
      {...props}
      onClose={handleDiscardChanges}
      sx={{
        '.modal-body': { width: isSmallScreen ? '100%' : '748px', paddingBottom: '0 !important' }
      }}
    >
      <Box className='change-modal'>
        {isLoading ? null : (
          <>
            <Box className='change-modal-header' ref={ref}>
              <Typography className='change-modal-title'>Change programme</Typography>

              <IconButton className='change-modal-close' onClick={handleDiscardChanges}>
                <CrossMarkIcon />
              </IconButton>
            </Box>

            <div className='change-modal-form'>
              {!!locations.length ? (
                <Select
                  onChange={handleLocationChange}
                  label='Select your preferred location'
                  options={locations?.map(item => item.title) || []}
                  initialValue={product.location.title}
                  value={product.location.title}
                  name='location'
                  placeholder='Select Location'
                />
              ) : (
                <Box>
                  <Skeleton width='100%' height='21px' sx={{ transform: 'scale(1)', marginBottom: '8px' }} />
                  <Skeleton width='100%' height='48px' sx={{ transform: 'scale(1)' }} />
                </Box>
              )}

              {!!ages.length && locations?.find(item => item.id === product?.location?.id)?.title !== 'Online' && (
                <RadioButtonsGroup
                  isSmall
                  isColumn={isSmallScreen}
                  label='Select age'
                  placeholder='Select age'
                  name='age'
                  value={product?.age?.id || ''}
                  initialValue={product?.age?.id || ''}
                  groupName='age'
                  options={ages?.map(item => ({ label: item.title, value: item.id })) || []}
                  onChange={handleAgeChange}
                />
              )}

              {!!educationalModels.length &&
                locations?.find(item => item.id === product?.location?.id)?.title === 'Online' && (
                  <Select
                    onChange={handleEducationalModelChange}
                    label='Choose online educational model'
                    isEducationModel
                    options={
                      educationalModels?.map(item => (
                        <Box
                          sx={{
                            borderRadius: '16px',
                            padding: '16px',
                            backgroundColor: '#F5F5F7',
                            width: '100%'
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                              <Box sx={{ paddingTop: '6px' }}>
                                {item.id === product?.educationalModel?.id ? <CheckedIcon /> : <CircleIcon />}
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  minHeight: 'auto',
                                  color: '#181C2A',
                                  lineHeight: '21px'
                                }}
                              >
                                {item.title}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: '82px',
                                height: '32px',
                                padding: '6px 8px 6px 8px',
                                borderRadius: '24px',
                                backgroundColor: '#FFFFFF'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  fontWeight: 450,
                                  minHeight: 'auto',
                                  color: '#2D4960'
                                }}
                              >
                                Ages {item.minAge}-{item.maxAge}
                              </Typography>
                            </Box>
                          </Box>
                          {item?.description && (
                            <Box sx={{ marginTop: '8px', maxWidth: selectWidth - 72, marginLeft: '34px' }}>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  lineHeight: '18px',
                                  fontWeight: 300,
                                  height: 'auto',
                                  color: '#6F6F6F',
                                  whiteSpace: 'wrap'
                                }}
                              >
                                {item?.description}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )) ?? []
                    }
                    value={(function () {
                      const model = educationalModels?.find(item => item.id === product?.educationalModel?.id)
                      if (model) {
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 450, minHeight: 'auto' }}>
                              {model.title}
                            </Typography>
                            <Box
                              sx={{ width: '4px', height: '4px', backgroundColor: '#BB945C', borderRadius: '50%' }}
                            />
                            <Typography
                              sx={{
                                fontSize: '16px',
                                fontWeight: 450,
                                paddingRight: '16px',
                                minHeight: 'auto',
                                color: '#6F6F6F'
                              }}
                            >
                              Ages {model.minAge}-{model.maxAge}
                            </Typography>
                          </Box>
                        )
                      }
                      return ''
                    })()}
                    name='educationalModel'
                    placeholder='Choose online educational model'
                  />
                )}

              {!!subjects.length ? (
                <Select
                  onChange={handleSubjectChange}
                  label='Select available subject for your second programme'
                  options={subjects?.map(item => item?.title?.replace(/&amp;/g, '&')) ?? []}
                  initialValue={product?.subject?.title?.replace(/&amp;/g, '&') || ''}
                  value={product?.subject?.title?.replace(/&amp;/g, '&') || ''}
                  name='subject'
                  placeholder='Select subject'
                />
              ) : (
                <Box>
                  <Skeleton width='100%' height='21px' sx={{ transform: 'scale(1)', marginBottom: '8px' }} />
                  <Skeleton width='100%' height='48px' sx={{ transform: 'scale(1)' }} />
                </Box>
              )}

              {isItemsLoading ? null : (
                <>
                  {!!classifications.length && product?.subject?.id && (
                    <RadioButtonGroup
                      label='Select your programme option'
                      name='educationModel'
                      value={product?.displayedPackage?.id || ''}
                      groupName='classifications'
                      options={classifications ?? []}
                      onChange={handleClassificationValueChange}
                    />
                  )}

                  {dates && !!dates.length && !isDateLoading && (
                    <RadioButtonGroup
                      isSmall
                      name='dates'
                      isColumn={isSmallScreen}
                      label='Select your preferred dates'
                      value={product?.programDate?.id || ''}
                      initialValue={product?.programDate?.id || ''}
                      groupName='dates'
                      options={dates ?? []}
                      onChange={handleDateValueChange}
                    />
                  )}

                  {!dates?.length && product?.package?.id && !isDateLoading && (
                    <Input
                      value={parentMonths}
                      onClick={handleOpenDateModal}
                      label={
                        <Typography className='calendar-label'>
                          Select your preferred month for your Programme{' '}
                          <span className='calendar-label-grey'>(we`ll do our best to cater to it)</span>
                        </Typography>
                      }
                      name='parentMonths'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton aria-label='toggle password visibility' edge='end'>
                            <CalendarIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}

                  {product?.package?.id &&
                    product.location.title !== 'Online' &&
                    product?.package?.classification?.title?.toLowerCase() !== 'non - residential' &&
                    product?.location?.airportTransferPrice && (
                      <RadioButtonGroup
                        isSmall
                        isMostPopular
                        name='transferOptions'
                        isColumn={isSmallScreen}
                        modalProps={{
                          '& .title': {
                            fontSize: '14px',
                            fontWeight: 450,
                            lineHeight: '21px',
                            color: '#1d1d1d'
                          },
                          '& .box': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: '8px'
                          },
                          '& .dot': {
                            height: '4px',
                            width: '4px',
                            borderRadius: '50%',
                            background: '#1d1d1d'
                          },
                          '& .item': {
                            fontSize: '14px',
                            fontWeight: 300,
                            lineHeight: '21px',
                            color: '#1d1d1d'
                          }
                        }}
                        tooltipProps={{
                          tooltip: {
                            sx: {
                              '& .title': {
                                fontSize: '14px',
                                fontWeight: 450,
                                lineHeight: '21px',
                                color: '#1d1d1d'
                              },
                              '& .box': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: '8px'
                              },
                              '& .dot': {
                                height: '4px',
                                width: '4px',
                                borderRadius: '50%',
                                background: '#1d1d1d'
                              },
                              '& .item': {
                                fontSize: '14px',
                                fontWeight: 300,
                                lineHeight: '21px',
                                color: '#1d1d1d'
                              }
                            }
                          }
                        }}
                        tooltip={
                          <Box>
                            <Typography className='title'>
                              Airport transfers will be from the following destinations
                            </Typography>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>London Heathrow Airport</Typography>
                            </Box>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>Sydney Kingsford Smith Airport</Typography>
                            </Box>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>Toronto Pearson International Airport</Typography>
                            </Box>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>John F. Kennedy International Airport</Typography>
                            </Box>
                            <Box className='box'>
                              <Box className='dot' />
                              <Typography className='item'>San Francisco International Airport </Typography>
                            </Box>
                            <Typography className='item'>
                              Transfer details will be confirmed closer to the flight time.
                            </Typography>
                          </Box>
                        }
                        label='Would you like support with airport transfer?'
                        value={transferOptions}
                        groupName='transfer'
                        options={transferOptionsData}
                        onChange={handleTransferOptionsChange}
                      />
                    )}

                  {product?.childProductOrder && (
                    <>
                      {
                        <Select
                          onChange={handleOnlineSubjectChange}
                          label='Select preferred subject for Online Research Programme'
                          options={childSubjects?.map(item => item?.title?.replace(/&amp;/g, '&')) ?? []}
                          initialValue={onlineSubject}
                          value={onlineSubject}
                          name='onlineSubject'
                          placeholder='Select subject'
                          error={!childSubjects.length}
                          helperText={
                            !childSubjects.length && (
                              <Typography
                                sx={{
                                  marginTop: '1px',
                                  marginLeft: '-14px',
                                  color: '#c8102e',
                                  fontSize: '12px',
                                  fontWeight: 450,
                                  lineHeight: '18px'
                                }}
                              >
                                There are no available subjects for Online Research Programme, please change location or
                                package.
                              </Typography>
                            )
                          }
                        />
                      }

                      {product?.childProductOrder && (
                        <Input
                          value={months}
                          onClick={handleOpenDateModal}
                          label={
                            <Typography className='calendar-label'>
                              Select your preferred month for your Online Research Programme{' '}
                              <span className='calendar-label-grey'>(we`ll do our best to cater to it)</span>
                            </Typography>
                          }
                          name='months'
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton aria-label='toggle password visibility' edge='end'>
                                <CalendarIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    </>
                  )}
                </>
              )}
              <Box className='buttons-block'>
                <CustomButton
                  onClick={handleDiscardChanges}
                  isLight
                  sx={{ color: '#6F6F6F !important', fontWeight: '450' }}
                >
                  Discard Changes
                </CustomButton>

                <CustomButton
                  type='submit'
                  onClick={handleSubmit}
                  className='submit-button'
                  disabled={isDisabledSubmitButton}
                >
                  Save
                </CustomButton>
              </Box>
            </div>
          </>
        )}
      </Box>

      {isMainProduct && !isMainProductSaved && (
        <Box
          onClick={resetOrder}
          sx={{ paddingTop: '24px', borderTop: '1px solid #F0F0F1', paddingBottom: '32px', cursor: 'pointer' }}
        >
          <Typography
            sx={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '450', lineHeight: '24px' }}
          >
            Return to programme selection
            <ArrowTopRight sx={{ marginLeft: '8px' }} />
          </Typography>

          <Typography sx={{ fontSize: '14px', fontWeight: 300, lineHeight: '21px', marginTop: '4px' }}>
            Reset your current selection, and choose another programme.
          </Typography>
        </Box>
      )}

      <DateModal
        open={isOpenDateModal}
        onClose={() => setIsOpenDateModal(false)}
        onSubmit={handleMonthsSubmit}
        programDate={product?.programDate}
      />

      <Toast />
    </StyledChangeProgrammeModal>
  )
})

export default ChangeProgrammeModal
